import { AnyData } from '../../../../../components/AnyData/AnyData'
import { AnyDataTab } from '../../../../../components/AnyData/AnyDataTypes'
import { IUserpData } from '@copilot-dash/domain'
import { TreeItemNoGroundingDataPermission } from '../ticket-kusto/tabs/sss-result/common/TreeItemNoGroundingDataPermission'
import { Row } from '../../../../../components/Layout'
import { ErrorView } from '../../../../../components/Error'

interface IProps {
  selectedDefinition: IUserpData
}

export function UserpSearchDataView({ selectedDefinition }: IProps) {
  if (selectedDefinition.data) {
    return <AnyData data={selectedDefinition.data} options={{ tabs: [AnyDataTab.Json] }} />
  }

  if (selectedDefinition.error) {
    return (
      <Row vAlign="center" hAlign="center">
        <ErrorView error={selectedDefinition.error} />
      </Row>
    )
  }

  if (!app.auth.permission?.advanced) {
    return <TreeItemNoGroundingDataPermission />
  }

  return null
}
