import { forwardRef } from 'react'
import { Column, Row } from '../../../Layout'
import { ISearchPageFilterProps } from '../../TicketFilterPanel.types'
import { TicketFilterForm } from '../TicketFilterForm/TicketFilterForm'
import { ITicketFilterFormRef } from '../TicketFilterForm/TicketFilterForm.types'
import { ProductIds } from '@copilot-dash/domain'
import { CustomizedFilterDialog } from '../../../CustomizedFilterDialog/CustomizedFilterDialog'
import { useTicketFilterStore } from '../../store/store'
import { Button, makeStyles, tokens, Spinner } from '@fluentui/react-components'
import { useFilterOptions } from '@copilot-dash/store'
import { AddRegular } from '@fluentui/react-icons'

export const FilterTabBody = forwardRef<ITicketFilterFormRef, ISearchPageFilterProps>(
  (props: ISearchPageFilterProps, ref) => {
    const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
    const styles = useStyles()
    const filterOptionsSnapshot = useFilterOptions('', '')

    return (
      <Column fill>
        {filterOptionsSnapshot.status === 'waiting' ? (
          <Row fill vAlign="center" hAlign="center">
            <div className={styles.loading}>
              <Spinner labelPosition="below" label="Loading..." />
            </div>
          </Row>
        ) : (
          <Column fill key="content">
            <TicketFilterForm
              ref={ref}
              onSubmit={props.onSubmitFilterPanel}
              defaultValues={{
                ...props.ticketFilterForm,
                range: props.ticketFilterForm.range ?? props.defaultRange,
              }}
            />

            <Row>
              {props.selectedProductId !== ProductIds.TenantAdminFeedback && (
                <CustomizedFilterDialog
                  ticketFilterForm={props.ticketFilterForm}
                  supportedTicketFilterItems={filterPanelItems}
                >
                  <Button appearance="transparent" className={styles.button} icon={<AddRegular fontSize={18} />}>
                    Add filters
                  </Button>
                </CustomizedFilterDialog>
              )}
            </Row>
          </Column>
        )}
      </Column>
    )
  },
)
FilterTabBody.displayName = 'FilterTabBody'

const useStyles = makeStyles({
  button: {
    textAlign: 'center',
    padding: '0',
    color: tokens.colorBrandForegroundLink,
    margin: '16px 0',
    cursor: 'pointer',
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
})
