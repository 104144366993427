import { Monaco } from '@monaco-editor/react'
import { initGlobalMonacoHoverProvider } from './initGlobalMonacoHoverProvider'

let initialized = false

export function initGlobalMonaco(monaco: Monaco) {
  if (initialized) {
    return
  }

  initGlobalMonacoHoverProvider(monaco)
  initialized = true
}
