import {
  BasicSearchTicketArgsNames,
  ISearchTicketArgs,
  TagMetaDataSearchTicketArgsNames,
  TeamViewSearchTicketArgsNames,
} from '@copilot-dash/store'
import { ProductIds, TeamViewSubMenuIds } from '@copilot-dash/domain'

import { z } from 'zod'

export type SearchFilterFieldNames = keyof ISearchTicketArgs
const combinedSearchTicketArgsKeys = {
  ...BasicSearchTicketArgsNames,
  ...TagMetaDataSearchTicketArgsNames,
  ...TeamViewSearchTicketArgsNames,
} as const

// Define the schema using the combined keys
export const searchFilterFieldNamesSchema = z.enum(
  Object.keys(combinedSearchTicketArgsKeys) as [keyof typeof combinedSearchTicketArgsKeys],
)
export const SearchFilterFieldNamesArraySchema = z.array(searchFilterFieldNamesSchema)

export const DefaultCustomizedFilter: SearchFilterFieldNames[] = ['isTopi18N']

export type SearchRouteConfig = {
  search: SearchFilterFieldNames[]
}
type ISubMenuName = keyof typeof TeamViewSubMenuIds | 'default'

export type TeamRouteConfig = {
  team: {
    [teamName: string]: {
      [subMenuName in ISubMenuName]?: SearchFilterFieldNames[]
    }
  }
}

export type IFilterPanelConfig = Partial<{
  [product in ProductIds]: SearchRouteConfig | TeamRouteConfig
}>
export const FilterPanelConfig: IFilterPanelConfig = {
  [ProductIds.M365Chat]: {
    search: [
      'agentTypes',
      'appTypes',
      'applications',
      'platforms',
      'customerTypes',
      'ring',
      'channel',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'priority',
      'promptLanguages',
      'groundedPrompts',
      'invocationSlicers',
      'invocationType',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'responseHeroType',
      'responseLinkType',
      'semanticSearchType',
      'hasEntityCard',
      'experienceType',
      'hasConnector',
      'hasGPTExtension',
      'hasMessageExtension',
      'hasCopilotExtensionIds',
      'errorCode',
      'isGCIntent',
      'hasConnectorResult',
      'dSATStatus',
      'customTags',
      'copilotExtensionIds',
      'flights',
      'hasScreenshot',
      'chatMode',
      'utteranceGroups',
      'scenarioSlicers',
    ],
    team: {
      default: {
        default: [
          'agentTypes',
          'appTypes',
          'applications',
          'platforms',
          'customerTypes',
          'ring',
          'channel',
          'hasVerbatim',
          'priority',
          'promptLanguages',
          'groundedPrompts',
          'isApology',
          'hasCitation',
          'triggeredSkill',
          'invocationType',
          'hitAvalon',
          'isSTCAChina',
          'hasErrorMessages',
          'isTopi18N',
          'responseHeroType',
          'responseLinkType',
          'semanticSearchType',
          'hasEntityCard',
          'experienceType',
          'hasConnector',
          'hasGPTExtension',
          'hasMessageExtension',
          'hasCopilotExtensionIds',
          'errorCode',
          'isGCIntent',
          'hasConnectorResult',
          'dSATStatus',
          'customTags',
          'dSATAssignedTo',
          'copilotExtensionIds',
          'flights',
        ],
        TopIssues: [
          'agentTypes',
          'appTypes',
          'applications',
          'platforms',
          'customerTypes',
          'ring',
          'channel',
          'hasVerbatim',
          'priority',
          'promptLanguages',
          'groundedPrompts',
          'isApology',
          'hasCitation',
          'triggeredSkill',
          'invocationType',
          'hitAvalon',
          'isSTCAChina',
          'hasErrorMessages',
          'isTopi18N',
          'responseHeroType',
          'responseLinkType',
          'semanticSearchType',
          'hasEntityCard',
          'experienceType',
          'hasConnector',
          'hasGPTExtension',
          'hasMessageExtension',
          'hasCopilotExtensionIds',
          'errorCode',
          'isGCIntent',
          'hasConnectorResult',
          'dSATStatus',
          'customTags',
          'dSATAssignedTo',
          'copilotExtensionIds',
          'flights',
        ],
      },
    },
  },
  [ProductIds.M365ChatWebChat]: {
    search: [
      'agentTypes',
      'appTypes',
      'applications',
      'platforms',
      'licenses',
      'authTypes',
      'customerTypes',
      'ring',
      'channel',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'priority',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'dSATStatus',
      'customTags',
      'optionsSets',
      'sliceIds',
      'hasCopilotExtensionIds',
      'copilotExtensionIds',
      'flights',
      'hasScreenshot',
      'chatMode',
    ],
    team: {
      default: {
        default: [
          'agentTypes',
          'appTypes',
          'applications',
          'platforms',
          'licenses',
          'customerTypes',
          'ring',
          'channel',
          'hasVerbatim',
          'priority',
          'promptLanguages',
          'isApology',
          'hasCitation',
          'triggeredSkill',
          'hitAvalon',
          'isSTCAChina',
          'hasErrorMessages',
          'isTopi18N',
          'errorCode',
          'dSATStatus',
          'customTags',
          'dSATAssignedTo',
          'optionsSets',
          'sliceIds',
          'hasCopilotExtensionIds',
          'copilotExtensionIds',
          'flights',
        ],
        TopIssues: [
          'agentTypes',
          'appTypes',
          'applications',
          'platforms',
          'licenses',
          'customerTypes',
          'ring',
          'channel',
          'hasVerbatim',
          'priority',
          'promptLanguages',
          'isApology',
          'hasCitation',
          'triggeredSkill',
          'hitAvalon',
          'isSTCAChina',
          'hasErrorMessages',
          'isTopi18N',
          'errorCode',
          'dSATStatus',
          'customTags',
          'dSATAssignedTo',
          'optionsSets',
          'sliceIds',
          'hasCopilotExtensionIds',
          'copilotExtensionIds',
          'flights',
        ],
      },
    },
  },
  [ProductIds.ExcelCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'platforms',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'hasCopilotExtensionIds',
      'copilotExtensionIds',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.WordCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'platforms',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'hasCopilotExtensionIds',
      'copilotExtensionIds',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.PPTCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'platforms',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'hasCopilotExtensionIds',
      'copilotExtensionIds',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.TeamsMeetingCopilot]: {
    search: [
      'meetingScenarios',
      'agentTypes',
      'appTypes',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'priority',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'invocationType',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'experienceType',
      'flights',
      'hasScreenshot',
    ],
    team: {
      default: {
        default: [
          'meetingScenarios',
          'agentTypes',
          'appTypes',
          'customerTypes',
          'ring',
          'hasVerbatim',
          'priority',
          'promptLanguages',
          'isApology',
          'hasCitation',
          'triggeredSkill',
          'hitAvalon',
          'isSTCAChina',
          'hasErrorMessages',
          'isTopi18N',
          'errorCode',
          'dSATStatus',
          'customTags',
          'dSATAssignedTo',
          'experienceType',
          'invocationType',
          'flights',
        ],
        TopIssues: [
          'meetingScenarios',
          'agentTypes',
          'appTypes',
          'customerTypes',
          'ring',
          'hasVerbatim',
          'priority',
          'promptLanguages',
          'isApology',
          'hasCitation',
          'triggeredSkill',
          'hitAvalon',
          'isSTCAChina',
          'hasErrorMessages',
          'isTopi18N',
          'errorCode',
          'dSATStatus',
          'customTags',
          'dSATAssignedTo',
          'experienceType',
          'invocationType',
          'flights',
        ],
      },
    },
  },
  [ProductIds.ODSPCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'applications',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.LoopCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'platforms',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.OneNoteCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'platforms',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.OutlookCopilot]: {
    search: [
      'agentTypes',
      'appTypes',
      'applications',
      'customerTypes',
      'ring',
      'thumbs',
      'hasVerbatim',
      'hasUserConsent',
      'promptLanguages',
      'isApology',
      'hasCitation',
      'triggeredSkill',
      'hitAvalon',
      'isSTCAChina',
      'hasErrorMessages',
      'isTopi18N',
      'errorCode',
      'flights',
      'hasScreenshot',
    ],
  },
  [ProductIds.TenantAdminFeedback]: {
    search: ['customerTypes', 'ring', 'hasVerbatim', 'hasScreenshot'],
  },
  [ProductIds.USERP]: {
    search: ['customerTypes', 'ring', 'thumbs', 'hasVerbatim', 'hasUserConsent', 'hasScreenshot'],
  },
  [ProductIds.SearchChat]: {
    search: ['customerTypes', 'ring', 'thumbs', 'hasVerbatim', 'hasUserConsent', 'hasScreenshot'],
  },
  [ProductIds.CopilotStudio]: {
    search: ['customerTypes', 'ring', 'thumbs', 'hasVerbatim', 'hasUserConsent', 'hasScreenshot'],
  },
}
