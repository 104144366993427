import { makeStyles, Spinner, tokens } from '@fluentui/react-components'
import { Column, Row } from '../../../../../components/Layout'
import { useMemo, useState } from 'react'
import { UserpSearchNavigator } from './UserpSearchNavigator'
import { UserpSearchDataView } from './UserpSearchDataView'
import { ErrorView } from '../../../../../components/Error'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function UserpSearchData({ ticketId, selectedTurnId }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getTicketTurnUserpContext(ticketId, selectedTurnId)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleOnSelect = (index: number) => {
    setSelectedIndex(index)
  }
  const selectedDefinition = useMemo(() => {
    return snapshot.data?.[selectedIndex]
  }, [selectedIndex, snapshot.data])

  switch (snapshot.status) {
    case 'waiting':
      return (
        <Row hAlign="center" vAlign="center" className={styles.row}>
          <Spinner label={'Loading...'} labelPosition="below" />
        </Row>
      )
    case 'error':
      return (
        <Row vAlign="center" hAlign="center">
          <ErrorView error={snapshot.error} />
        </Row>
      )

    case 'done':
      return (
        selectedDefinition && (
          <Row hAlign="stretch" vAlign="stretch" fill className={styles.root}>
            {/* Tabs */}
            <Column style={{ width: 200 }} hAlign="stretch" vAlign="stretch">
              <UserpSearchNavigator selected={selectedIndex} definitions={snapshot.data} onSelect={handleOnSelect} />
            </Column>
            <Column className={styles.main} style={{ flex: 1 }} hAlign="stretch" vAlign="stretch">
              <UserpSearchDataView key={selectedDefinition.title} selectedDefinition={selectedDefinition} />
            </Column>
          </Row>
        )
      )
  }
}

const useStyles = makeStyles({
  root: {
    padding: '20px',
  },
  route: {
    fontWeight: tokens.fontWeightSemibold,
  },
  openIcon: {
    verticalAlign: '-0.15em',
  },
  main: {
    overflow: 'hidden',
  },
  row: {
    width: '100%',
    height: '100%',
  },
})
