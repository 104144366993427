import { MonacoJsonView } from '../../../../../../../components/Monaco/MonacoJsonView'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function JsonView({ ticketId, messageId }: IProps) {
  const snapshot = app.store.use.getRawConversationGroup3(ticketId, messageId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        return <MonacoJsonView data={data} />
      }}
    </TicketAsyncView>
  )
}
