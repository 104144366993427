import { z } from 'zod'
import { TeamId } from '@copilot-dash/domain'

export type ActorType = 'system' | 'user' | 'unset'
export type SystemType = 'logcollector' | 'TFS' | 'Unset' | 'Automation' | 'Clustering'

export type ActivityHistoryType =
  | 'TicketStatusUpdate'
  | 'AddComment'
  | 'UpdateComment'
  | 'DeleteComment'
  | 'SetTicketRootCausingActions'
  | 'Unset'
  | 'FeedbackCreated'
  | 'FeedbackCooked'
  | 'WorkItemCreated'
  | 'WorkItemAutoTriage'
  | 'UpdateCustomTags'

export interface IActivityHistory {
  revision: number
  activityId: string
  ticketId: string
  timestamp: string
  activity: ActivityHistoryType
  actor?: ActorType
  userId?: string
  system?: SystemType
  fieldDiffs?: ActivityFieldDiff
}

export interface TicketIssueData {
  readonly IssueId: string
  readonly VsoAccount?: string
  readonly RootCauseTitle?: string
}

export interface ActivityHistoryRootCauseContext {
  readonly AdoIssueId: string
  readonly Title: string
  readonly UserAction: string
  readonly Project: string
  readonly Areapath: string
  readonly AdoLink: string
  readonly TeamName?: string
}
export interface ActivityFieldDiff {
  CommentId?: { newValue?: string; oldValue?: string }
  CommentContent?: { newValue?: string; oldValue?: string }

  State?: { newValue?: string; oldValue?: string }
  Priority?: { newValue?: string; oldValue?: string }
  TeamArea?: { newValue?: string; oldValue?: string }
  TeamId?: { newValue?: TeamId; oldValue?: TeamId }
  AssignTo?: { newValue?: string; oldValue?: string }
  IssueList?: { newValue?: TicketIssueData[]; oldValue?: TicketIssueData[] }

  CustomTags?: { newValue?: string[]; oldValue?: string[] }

  RootCauseActiveContext?: {
    newValue?: ActivityHistoryRootCauseContext[]
    oldValue?: ActivityHistoryRootCauseContext[]
  }
}

export const activityHistoryRootCauseContextSchema = z.object({
  AdoIssueId: z.string(),
  Title: z.string(),
  UserAction: z.string(),
  Project: z.string(),
  Areapath: z.string(),
  AdoLink: z.string(),
  TeamName: z.string().optional(),
})

export const ticketIssueDataSchema = z.object({
  IssueId: z.string(),
  VsoAccount: z.string().optional(),
  RootCauseTitle: z.string().optional(),
})

export const activityHistoryTypeSchema = z.union([
  z.literal('TicketStatusUpdate'),
  z.literal('AddComment'),
  z.literal('UpdateComment'),
  z.literal('DeleteComment'),
  z.literal('SetTicketRootCausingActions'),
  z.literal('Unset'),
  z.literal('FeedbackCreated'),
  z.literal('FeedbackCooked'),
  z.literal('WorkItemCreated'),
  z.literal('WorkItemAutoTriage'),
  z.literal('UpdateCustomTags'),
])

export const actorTypeSchema = z.union([z.literal('system'), z.literal('user'), z.literal('unset')])
export const systemTypeSchema = z.union([
  z.literal('logcollector'),
  z.literal('TFS'),
  z.literal('Unset'),
  z.literal('Automation'),
  z.literal('Clustering'),
])
