import { FC, useMemo } from 'react'
import { Text, shorthands, makeStyles, tokens } from '@fluentui/react-components'
import { Row, Column } from '../../../Layout'
import { useActivityHistoryPanelStore } from '../../store/store'

export const ActivityConfirmRootCause: FC = () => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  const Actions = useMemo(() => {
    if (selectedActivity?.fieldDiffs?.RootCauseActiveContext) {
      const { newValue } = selectedActivity.fieldDiffs.RootCauseActiveContext
      if (Array.isArray(newValue)) {
        return newValue
      }
    }
    return []
  }, [selectedActivity])

  return (
    <Column>
      <Row>
        {Actions.length > 0 && (
          <Text className={styles.tag}>
            {Actions.map((action, idx) => (
              <Text key={idx} title={action.Title}>
                {action.Title}
              </Text>
            ))}
          </Text>
        )}
      </Row>
    </Column>
  )
}

const useStyles = makeStyles({
  tag: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.padding('4px', '4px'),
    ...shorthands.margin('4px', '4px'),
    borderRadius: '4px',
  },
})
