import { makeStyles } from '@fluentui/react-components'
import { Editor } from '@monaco-editor/react'
import { useMemo } from 'react'
import { useThemeMode } from '../../../../../theme/useThemeMode'
import { initGlobalMonaco } from '../../../../Monaco/initGlobalMonaco'
import { AnyDataStore } from '../../../AnyDataStore'

export function AnyJson() {
  const styles = useStyles()
  const theme = useThemeMode()

  const data = AnyDataStore.use((state) => state.data)
  const wrap = AnyDataStore.use((state) => state.json.wrap)
  const dataJsonString = useMemo(() => {
    try {
      return JSON.stringify(data, null, 2)
    } catch (e) {
      return String(e)
    }
  }, [data])

  return (
    <Editor
      beforeMount={initGlobalMonaco}
      language={'json'}
      value={dataJsonString}
      className={styles.root}
      theme={theme === 'light' ? 'light' : 'vs-dark'}
      options={{
        readOnly: true,
        wordWrap: wrap ? 'on' : 'off',
        minimap: { enabled: false },
        scrollbar: { vertical: 'visible' },
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    minHeight: '200px',
    minWidth: '400px',
    width: '100%',
  },
})
