import { createStoreContext, useStore } from '@copilot-dash/core'
import { last } from 'lodash'
import { ReactNode, useEffect } from 'react'
import { createActions } from './store/createActions'
import { createState } from './store/createState'
import { IActions } from './store/IActions'
import { IState, TicketScreenKustoTab, TicketScreenMainTab } from './store/IState'
import { getSupportedKustoTabs } from './utils/getSupportedKustoTabs'
import { getSupportedMainTabs } from './utils/getSupportedMainTabs'

interface IProps {
  readonly ticketId: string
  readonly children: ReactNode
}

export function TicketScreenStore({ ticketId, children }: IProps) {
  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({ ticketId }),
    actions: createActions(set, get),
  }))

  // Init first conversation
  useEffect(() => {
    application.store.actions.getTicketMessageIds(ticketId).then((ticketMessageIds) => {
      const messageId = last(ticketMessageIds)
      if (messageId) {
        store.setState((state) => {
          state.selectedTurnId = messageId
        })
      }
    })
  }, [store, ticketId])

  // Init supported main tabs
  useEffect(() => {
    const updateTabs = (tabs: TicketScreenMainTab[]) => {
      store.setState((state) => {
        state.supportedMainTabs = tabs
        state.selectedMainTab = tabs[0]
      })
    }

    getSupportedMainTabs(ticketId)
      .then(updateTabs)
      .catch(() => {})
  }, [store, ticketId])

  // Init supported kusto tabs
  useEffect(() => {
    const updateTabs = (tabs: TicketScreenKustoTab[]) => {
      store.setState((state) => {
        state.supportedKustoTabs = tabs
        state.selectedKustoTab = tabs[0]
      })
    }

    getSupportedKustoTabs(ticketId)
      .then(updateTabs)
      .catch(() => {
        updateTabs([])
      })
  }, [store, ticketId])

  // Report telemetry
  useEffect(() => {
    const reportTelemetry = async () => {
      const startTime = Date.now()

      const messageIds = await app.store.actions.getTicketMessageIds(ticketId)
      await Promise.all(
        messageIds.map((messageId) => {
          return app.store.actions.getTicketTurnConversation(ticketId, messageId)
        }),
      )

      const duration = Date.now() - startTime
      app.logger.telemetry.trackMetric('TicketPageFirstDataLoadTime', { duration })
    }

    reportTelemetry()
  }, [ticketId])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()
TicketScreenStore.use = Context.use
TicketScreenStore.useActions = Context.useActions
