import { ApiOdsFile, ApiOdsInteractionInfos } from '@copilot-dash/api'
import { IOdsBlobFile, ITicketSource, ITicketTurnSource } from '@copilot-dash/domain'
import { compact } from 'lodash'
import { convertToOdsBlobMetadata } from './convertToOdsBlobMetadata'

export function convertToTicketSourceFromOds(
  interactions: ApiOdsInteractionInfos[],
  files: ApiOdsFile[],
): ITicketSource {
  const metadata = convertToOdsBlobMetadata(files)

  return {
    ocv: undefined,
    turns: getTurns(),
    endpoint: 'ODS',
    raw: files,
  }

  function getTurns(): { [messageId: string]: ITicketTurnSource } {
    const map: Record<keyof ITicketTurnSource, Array<(IOdsBlobFile & { index: number }) | undefined>> = {
      conversation: metadata.conversations,
      conversationGroup1: metadata.groupedConversationFiles.map((file) => {
        return file.group1 ? { ...file.group1, index: file.index } : undefined
      }),
      conversationGroup2: metadata.groupedConversationFiles.map((file) => {
        return file.group2 ? { ...file.group2, index: file.index } : undefined
      }),
      conversationGroup3: metadata.groupedConversationFiles.map((file) => {
        return file.group3 ? { ...file.group3, index: file.index } : undefined
      }),
      conversationGroup4: metadata.groupedConversationFiles.map((file) => {
        return file.group4 ? { ...file.group4, index: file.index } : undefined
      }),
      userpGroup1: metadata.userp.map((file) => {
        return file.group1 ? { ...file.group1, index: file.index } : undefined
      }),
      userpGroup2: metadata.userp.map((file) => {
        return file.group2 ? { ...file.group2, index: file.index } : undefined
      }),
      userpGroup3: metadata.userp.map((file) => {
        return file.group3 ? { ...file.group3, index: file.index } : undefined
      }),
      userpGroup4: metadata.userp.map((file) => {
        return file.group4 ? { ...file.group4, index: file.index } : undefined
      }),
      substrateSearchOnlineGroup1: metadata.sssOnline.filter((file) => file.group === 'Group1'),
      substrateSearchOnlineGroup2: metadata.sssOnline.filter((file) => file.group === 'Group2'),
      substrateSearchOnlineGroup3: metadata.sssOnline.filter((file) => file.group === 'Group3'),
      substrateSearchOnlineGroup4: metadata.sssOnline.filter((file) => file.group === 'Group4'),
      substrateSearchOfflineGroup1: metadata.sssOffline.filter((file) => file.group === 'Group1'),
      substrateSearchOfflineGroup2: metadata.sssOffline.filter((file) => file.group === 'Group2'),
      substrateSearchOfflineGroup3: metadata.sssOffline.filter((file) => file.group === 'Group3'),
      substrateSearchOfflineGroup4: metadata.sssOffline.filter((file) => file.group === 'Group4'),
      substrateSearchLog: [],
      substrateSearchLatencyLog: [],
      performanceLog: [],
      llmLog: [],
      augLoopLog: [],
      stateDurationLog: [],
      traceLog: [],
      extensibility: [],
    }

    const turns: { [messageId: string]: ITicketTurnSource } = {}
    Object.entries(map).forEach((entry) => {
      const key = entry[0] as keyof ITicketTurnSource
      const items = compact(entry[1])

      for (const item of items) {
        const messageId = interactions.find((turn) => turn.Index === item.index)?.MessageId
        if (messageId) {
          turns[messageId] = {
            ...turns[messageId],
            [key]: {
              blobUrl: item.fileUrl,
              raw: item,
            },
          }
        }
      }
    })

    return turns
  }
}
