import { ScenarioSlicers, UtteranceGroups } from '@copilot-dash/domain'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { getTagDisplayName } from '../../config/SupportedTags'
import { useMemo } from 'react'

interface IProps {
  utteranceGroups: UtteranceGroups[]
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const ScenarioSlicerFilter: React.FC<IProps> = ({
  utteranceGroups: utteranceGroups,
  selectedValues,
  onChangeValue,
}) => {
  const scenarioList = useMemo(() => {
    const items = (
      utteranceGroups.length === 0
        ? ScenarioSlicers
        : ScenarioSlicers.filter((slicer) => utteranceGroups.includes(slicer.utteranceGroup))
    )
      .map((slicer) => slicer.scenarios)
      .flat()

    return items
      .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
      .map((item) => ({
        key: item,
        text: item,
      }))
  }, [utteranceGroups])

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-scenarioSlicerFilter'}
        disabled={!utteranceGroups?.length}
        filterType={getTagDisplayName('scenarioSlicers')}
        isGroup={true}
        optionsList={scenarioList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
        infoLabelContent={<>This field is computed offline and may have some delay. The SLA is 33 hours.</>}
      />
    </>
  )
}
