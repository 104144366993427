import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { Row, Spacer, Column } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { IActivityHistory } from '@copilot-dash/domain'
import { useActivityHistoryPanelStore } from '../../store/store'
import { isNil } from 'lodash'

interface IProps {
  readonly activity?: IActivityHistory
}

export const TeamFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  const teamsSnapshot = useActivityHistoryPanelStore((state) => state.teamsSnapshot)

  const newTeam = useMemo(() => {
    if (!isNil(activity?.fieldDiffs?.TeamArea?.newValue)) {
      return activity?.fieldDiffs?.TeamArea?.newValue
    }
    if (!isNil(activity?.fieldDiffs?.TeamId?.newValue) && teamsSnapshot.data) {
      const team = teamsSnapshot.data.find((team) => team.id === activity?.fieldDiffs?.TeamId?.newValue)
      return team?.name
    }
    return undefined
  }, [activity, teamsSnapshot])

  const oldTeam = useMemo(() => {
    if (!isNil(activity?.fieldDiffs?.TeamArea?.oldValue)) {
      return activity?.fieldDiffs?.TeamArea?.oldValue
    }
    if (!isNil(activity?.fieldDiffs?.TeamId?.oldValue) && teamsSnapshot.data) {
      const team = teamsSnapshot.data.find((team) => team.id === activity?.fieldDiffs?.TeamId?.oldValue)
      return team?.name
    }
    return undefined
  }, [activity, teamsSnapshot])

  if (newTeam === oldTeam) return null

  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Team:</Text>
      </Column>
      {newTeam && (
        <Text className={styles.tag} title={newTeam}>
          {newTeam}
        </Text>
      )}
      <Spacer width="4px" />
      {oldTeam && (
        <Text className={mergeClasses(styles.tag, styles.deleted)} title={oldTeam}>
          {oldTeam}
        </Text>
      )}
    </Row>
  )
}
