import { IM365TenantItem } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTenant } from './getTenant'
import { compact, uniqBy } from 'lodash'
import { searchTopTenantIdsByAISearch } from './searchTopTenantIdsByAISearch'

const DEFAULT_LIMIT = 20

interface IOptions {
  readonly limit?: number
}

export async function searchM365Tenants(
  context: IDashStoreContext,
  keyword: string,
  tenantIds?: string[],
  options?: IOptions,
): Promise<IM365TenantItem[]> {
  const getTenantList = async () => {
    if (!context.enableV2Endpoint) {
      const response = await context.api.logCollector.getM365TenantList({
        Keyword: keyword,
        Limit: options?.limit ?? DEFAULT_LIMIT,
      })
      return response.m365TenantList
    } else {
      if (keyword.length === 0) {
        const topTenantIds = await searchTopTenantIdsByAISearch(context).promise
        return await context.api.copilotDash.getTenantsByIds(topTenantIds)
      }
      return await context.api.copilotDash.getTenantList(keyword, options?.limit ?? DEFAULT_LIMIT)
    }
  }

  const tenantList = await getTenantList()

  if (tenantIds && tenantIds.length > 0) {
    const tenantIdResponse = await Promise.all(
      tenantIds.map((tenant) => getTenant(context, tenant).promise.catch(() => null)),
    )
    const filteredTenantIdResponse = compact(tenantIdResponse)

    return uniqBy([...filteredTenantIdResponse, ...tenantList], 'tenantId')
  }

  return tenantList
}
