import { CopilotDashPath, ProductIds, SearchFilterFieldNames, getProductIdByName } from '@copilot-dash/domain'
import { ISearchScreenForm, useSearchScreenActions, useSearchScreenState } from '../../store'
import {
  ITicketFilterFormData,
  ITicketFilterFormRef,
} from '../../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { isEqual, omitBy } from 'lodash'
import { memo, useEffect, useMemo, useRef } from 'react'

import { TicketsFilterPanel } from '../../../../components/TicketsFilterPanel/TicketsFilterPanel'
import { getFilterItems } from '../../../../components/TicketsFilterPanel/config/getFilterItems'

export const FilterPanel = memo(function FilterPanel() {
  const actions = useSearchScreenActions()
  const state = useSearchScreenState((state) => state)
  const form = state.form
  const formRef = useRef<ITicketFilterFormRef | null>(null)
  const currentQuery = state.currentQuery

  const onSubmitFilterPanel = (data?: ITicketFilterFormData) => {
    const updateForm: ISearchScreenForm = {
      ...data,
      range: data?.range,
      tenantIds: form.tenantIds,
      searchText: form.searchText,
      searchTextPrefix: form.searchTextPrefix,
      userId: form.userId,
      product: form.product,
      defaultRange: form.defaultRange,
    }
    if (form.queryId && currentQuery) {
      const filterPayload = JSON.parse(currentQuery.filterPayload)
      const cleanedUpdateForm = omitBy(updateForm, (value, key) => isEqual(value, filterPayload[key]))
      if (!isEqual(cleanedUpdateForm, form)) {
        actions.submit({
          ...cleanedUpdateForm,
          defaultRange: form.defaultRange,
          product: form.product,
        })
        Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FilterPanel', value: null })
      }
    } else {
      if (!isEqual(updateForm, form)) {
        actions.submit(updateForm)
        Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FilterPanel', value: null })
      }
    }
  }

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? form.defaultRange,
      })
    }
  }, [form])

  const onClickReset = () => {
    Logger.telemetry.trackEvent('TicketFilter/ResetButton/Click')
    actions.resetFilters()
  }
  const supportedTicketFilterItems: SearchFilterFieldNames[] = useMemo(() => {
    return getFilterItems(getProductIdByName(form.product) ?? ProductIds.M365Chat, 'search')
  }, [form.product])

  const searchScreenCustomizedFilters = app.settings.searchScreenCustomizedFilterItems.use()

  // In the short-term AI search implementation, we disable the priority and DSAT status filters in the "All Feedback" section.
  const isAISearchEnable = app.features.aiSearch.use()
  const isInvocationSlicerEnable = app.features.invocationSlicer.use()

  return (
    <TicketsFilterPanel
      ref={formRef}
      isPanelOpen={state.isTicketsFilterPanelOpen}
      onDismiss={() => actions.updateFiltersPanelVisibility(false)}
      onClickReset={onClickReset}
      isAIF={form.product === 'TenantAdminFeedback'}
      selectedProductId={getProductIdByName(form.product)}
      ticketFilterForm={form}
      onSubmitFilterPanel={onSubmitFilterPanel}
      defaultRange={form.defaultRange}
      supportedTicketFilterItems={supportedTicketFilterItems}
      customizedFilters={searchScreenCustomizedFilters}
      productName={form.product ?? 'M365Chat'}
      copilotDashPath={CopilotDashPath.search}
      currentQuery={state.currentQuery}
      isAISearchEnable={isAISearchEnable}
      isInvocationSlicerEnable={isInvocationSlicerEnable}
      selectedApplications={form.applications}
      selectedPlatforms={form.platforms}
      selectedLicenses={form.licenses}
      selectedUtteranceGroups={form.utteranceGroups}
    />
  )
})
