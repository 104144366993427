import { Monaco } from '@monaco-editor/react'
import { getWordTooltip } from './getWordTooltip'

export function initGlobalMonacoHoverProvider(monaco: Monaco) {
  registerHoverProviderForJson(monaco)
}

function registerHoverProviderForJson(monaco: Monaco) {
  monaco.languages.registerHoverProvider('json', {
    provideHover: (model, position) => {
      const word = model.getWordAtPosition(position)
      if (!word) return

      const tooltip = getWordTooltip(word.word)
      if (!tooltip) return

      return {
        range: {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        },
        contents: [
          {
            value: tooltip,
          },
        ],
      }
    },
  })
}
