import { FC, memo, useState, useEffect, useMemo } from 'react'
import { Field, Text, Checkbox } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { ITeamData, TeamId } from '@copilot-dash/domain'
import { Row } from '../../Layout'
import { CopilotDashTeamDropdown } from '../../Filter/CopilotDashTeamDropdown'

interface IProps {
  teamId: TeamId | undefined
  isTeamShared?: boolean
  onTeamIdChange: (value: string | undefined) => void
  onIsTeamSharedChange: (value: boolean) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  teamsOptions: ITeamData[]
}

export const Team: FC<IProps> = memo(
  ({
    teamId,
    isTeamShared = false,
    onTeamIdChange,
    onIsTeamSharedChange,
    orientation = 'vertical',
    className,
    style,
    disabled,
    fieldProps,
    teamsOptions,
  }) => {
    const [teamName, setTeamName] = useState<string>('')

    useEffect(() => {
      if (isNil(teamId)) {
        setTeamName('')
      } else {
        setTeamName(teamsOptions.find((team) => team.id === teamId)?.name ?? '')
      }
    }, [teamId, teamsOptions])

    const newTeamsOptions = useMemo(() => {
      return [...teamsOptions].sort((a, b) =>
        a.name.toLocaleUpperCase().trim().localeCompare(b.name.toLocaleUpperCase().trim()),
      )
    }, [teamsOptions])

    return (
      <Field
        className={className}
        orientation={orientation}
        style={style}
        {...(fieldProps ?? {})}
        onClick={(e) => e.stopPropagation()}
      >
        <Row>
          <Checkbox
            checked={isTeamShared}
            onChange={(ev, data) => onIsTeamSharedChange(!!data.checked)}
            label={
              <Text size={400} weight="semibold">
                Share with team{' '}
              </Text>
            }
            disabled={disabled}
          />
          {isTeamShared && (
            <CopilotDashTeamDropdown
              teamList={newTeamsOptions}
              teamId={teamId}
              teamName={teamName}
              onTeamIdChange={onTeamIdChange}
              placeholder="Select a Team"
              showInput={true}
            />
          )}
        </Row>
      </Field>
    )
  },
)

Team.displayName = 'Team'
