import { ISearchScreenPropsQuery } from '../../../screens/search/SearchScreen'
import { SearchRoute } from './SearchRoute'
import { useMemo } from 'react'

export function useSearchScreenQuery(): ISearchScreenPropsQuery {
  const args = SearchRoute.navigator.useArgs()

  return useMemo(() => {
    return {
      product: args?.product,
      applications: args?.applications,
      platforms: args?.platforms,
      licenses: args?.licenses,
      authTypes: args?.authTypes,
      channel: args?.channel,
      customerTypes: args?.customerTypes,
      groundedPrompts: args?.groundedPrompts,
      ring: args?.ring,
      promptLanguages: args?.promptLanguages,
      userId: args?.userId,
      range: args?.range,
      hasVerbatim: args?.hasVerbatim,
      hasUserConsent: args?.hasUserConsent,
      invocationSlicers: args?.invocationSlicers,
      invocationType: args?.invocationType,
      triggeredSkill: args?.triggeredSkill,
      hasCitation: args?.hasCitation,
      hasEntityCard: args?.hasEntityCard,
      hitAvalon: args?.hitAvalon,
      isApology: args?.isApology,
      isCooked: args?.isCooked,
      searchText: args?.searchText,
      searchTextPrefix: args?.searchTextPrefix,
      tenantIds: args?.tenantIds,
      thumbs: args?.thumbs,
      priority: args?.priority,
      isSTCAChina: args?.isSTCAChina,
      isTopi18N: args?.isTopi18N,
      responseHeroType: args?.responseHeroType,
      responseLinkType: args?.responseLinkType,
      semanticSearchType: args?.semanticSearchType,
      experienceType: args?.experienceType,
      hasConnector: args?.hasConnector,
      hasGPTExtension: args?.hasGPTExtension,
      hasMessageExtension: args?.hasMessageExtension,
      hasCopilotExtensionIds: args?.hasCopilotExtensionIds,
      optionsSets: args?.optionsSets,
      tab: args?.tab,
      issueId: args?.issueId,
      issueBatchId: args?.issueBatchId,
      errorCode: args?.errorCode,
      isGCIntent: args?.isGCIntent,
      hasConnectorResult: args?.hasConnectorResult,
      dSATStatus: args?.dSATStatus,
      customTags: args?.customTags,
      copilotExtensionIds: args?.copilotExtensionIds,
      sliceIds: args?.sliceIds,
      queryId: args?.queryId,
      flights: args?.flights,
      hasErrorMessages: args?.hasErrorMessages,
      agentTypes: args?.agentTypes,
      appTypes: args?.appTypes,
      meetingScenarios: args?.meetingScenarios,
      order: args?.order,
      hasScreenshot: args?.hasScreenshot,
      chatMode: args?.chatMode,
      utteranceGroups: args?.utteranceGroups,
      scenarioSlicers: args?.scenarioSlicers,
    }
  }, [
    args?.product,
    args?.applications,
    args?.platforms,
    args?.licenses,
    args?.authTypes,
    args?.channel,
    args?.customerTypes,
    args?.groundedPrompts,
    args?.ring,
    args?.promptLanguages,
    args?.userId,
    args?.range,
    args?.hasVerbatim,
    args?.hasUserConsent,
    args?.invocationSlicers,
    args?.invocationType,
    args?.triggeredSkill,
    args?.hasCitation,
    args?.hasEntityCard,
    args?.hitAvalon,
    args?.isApology,
    args?.isCooked,
    args?.searchText,
    args?.searchTextPrefix,
    args?.tenantIds,
    args?.thumbs,
    args?.priority,
    args?.isSTCAChina,
    args?.isTopi18N,
    args?.responseHeroType,
    args?.responseLinkType,
    args?.semanticSearchType,
    args?.experienceType,
    args?.hasConnector,
    args?.hasGPTExtension,
    args?.hasMessageExtension,
    args?.hasCopilotExtensionIds,
    args?.optionsSets,
    args?.tab,
    args?.issueId,
    args?.issueBatchId,
    args?.errorCode,
    args?.isGCIntent,
    args?.hasConnectorResult,
    args?.dSATStatus,
    args?.customTags,
    args?.copilotExtensionIds,
    args?.sliceIds,
    args?.queryId,
    args?.flights,
    args?.hasErrorMessages,
    args?.agentTypes,
    args?.appTypes,
    args?.meetingScenarios,
    args?.order,
    args?.hasScreenshot,
    args?.chatMode,
    args?.utteranceGroups,
    args?.scenarioSlicers,
  ])
}
