import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { InvocationSlicer } from '@copilot-dash/domain'
import { OptionSetInvocationSlicer } from '../../config/OptionSetInvocationSlicer'
import { getTagDisplayName } from '../../config/SupportedTags'
import { z } from 'zod'

interface IProps {
  selectedValues: InvocationSlicer[] | undefined
  onChangeValue: (value: InvocationSlicer[]) => void
}

export const InvocationSlicerFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = OptionSetInvocationSlicer

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-invocationSlicerFilter'}
      filterType={getTagDisplayName('invocationSlicers')}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const result = z.array(z.nativeEnum(InvocationSlicer)).safeParse(item)
        if (result.success) {
          onChangeValue(result.data)
        }
      }}
      infoLabelContent={<>This field is computed offline and may have some delay. The SLA is 33 hours.</>}
    />
  )
}
