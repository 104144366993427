import { Text, mergeClasses } from '@fluentui/react-components'
import { FC } from 'react'
import { Row, Spacer, Column } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { IActivityHistory } from '@copilot-dash/domain'

interface IProps {
  readonly activity?: IActivityHistory
}

export const StateFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  if (activity?.fieldDiffs?.State?.newValue === activity?.fieldDiffs?.State?.oldValue) return null

  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>State:</Text>
      </Column>
      {activity?.fieldDiffs?.State?.newValue && (
        <Text className={styles.tag} title={activity?.fieldDiffs?.State?.newValue}>
          {activity?.fieldDiffs?.State?.newValue}
        </Text>
      )}
      <Spacer width="4px" />
      {activity?.fieldDiffs?.State?.oldValue && (
        <Text className={mergeClasses(styles.tag, styles.deleted)} title={activity?.fieldDiffs?.State?.oldValue}>
          {activity?.fieldDiffs?.State?.oldValue}
        </Text>
      )}
    </Row>
  )
}
