import * as React from 'react'

import { BasicSearchTicketArgsNames, TagMetaDataSearchTicketArgsNames } from '@copilot-dash/store'
import { IQuery, ProductIds, TeamViewSubMenuIds } from '@copilot-dash/domain'
import { ITeamViewRouteArgs, TeamRoute } from '../../router'
import { isNil, pick, pickBy } from 'lodash'

import { TeamScreenLayout } from './layout/TeamScreenLayout'
import { TelemetryScope } from '@copilot-dash/logger'
import { TimeRange } from '@copilot-dash/core'
import { useGlobalStore } from '../../store'
import { useTeamViewStore } from './store'
import { generateDefaultArg } from './const'

interface ITeamScreenProps {
  args: ITeamViewRouteArgs
  productId: ProductIds
  currentQuery?: IQuery
}

export const TeamScreen: React.FC<ITeamScreenProps> = React.memo(({ args, currentQuery, productId }) => {
  const argsRef = React.useRef(args)
  argsRef.current = args

  const teamListSnapshot = application.store.use.getTeams(productId)

  React.useEffect(() => {
    useTeamViewStore.getState().route.setArgs(args)
  }, [args])

  React.useEffect(() => {
    useTeamViewStore.getState().setCurrentQuery(currentQuery)
  }, [currentQuery])

  React.useEffect(() => {
    useTeamViewStore.getState().teams.setProduct(args.product)
  }, [args.product])

  React.useEffect(() => {
    useTeamViewStore.getState().toggleFilterPanel(false)
  }, [args.product, args.subMenu, args.teamName, args.issueId])

  React.useEffect(() => {
    const { teamName, issueId, vsoAccount, subMenu } = args
    const filters = pick(args, [
      ...Object.values({
        ...BasicSearchTicketArgsNames,
        ...TagMetaDataSearchTicketArgsNames,
      }),
      'queryId',
    ])
    const generateDefaultRange = (days: number): TimeRange => ({
      type: 'relative',
      value: days,
      unit: 'days',
    })
    if (teamName && teamListSnapshot.status === 'done') {
      useTeamViewStore.getState().teams.setLastSelectedTeam(teamName, true, productId)
      useTeamViewStore.getState().rootCauses.fetchTeamRootCauses(teamName)
      useTeamViewStore.getState().toggleTicketDetail(false)
      useTeamViewStore.getState().toggleColumnSetting(false)
      useTeamViewStore.getState().toggleBatchUpdatePanel(false)
      useTeamViewStore.getState().tickets.setFilterForm(filters)
      useTeamViewStore.getState().setSelectedTicket(null)

      if (subMenu === TeamViewSubMenuIds.TopIssues) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.TopIssues)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
      } else if (subMenu === TeamViewSubMenuIds.RootCauses) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.RootCauses)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
        if (issueId) {
          useTeamViewStore.getState().tickets.setTicketsTabType('rootCause')
          useTeamViewStore.getState().rootCauses.setSelectedRootCause(issueId, vsoAccount)
          if (!filters.range) {
            useTeamViewStore.getState().tickets.setFilterForm({ ...filters, range: generateDefaultRange(540) })
          }
        }
      } else if (subMenu === TeamViewSubMenuIds.All) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.All)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
        useTeamViewStore.getState().tickets.fetchTicketListForAll(filters)
        if (!filters.range) {
          useTeamViewStore.getState().tickets.setFilterForm({ ...filters, range: generateDefaultRange(180) })
        }
      } else if (subMenu === TeamViewSubMenuIds.UnRootCaused) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.UnRootCaused)
        useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
        if (!filters.range) {
          useTeamViewStore.getState().tickets.setFilterForm({ ...filters, range: generateDefaultRange(30) })
        }
      } else if (subMenu === TeamViewSubMenuIds.SharedQueries) {
        useTeamViewStore.getState().tickets.setTicketsTabType('category')
        useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.SharedQueries)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // NOTE: @Ethan - We need specific dependencies here to trigger search tickets. Cannot use args directly.

    args.teamName,
    args.ticketStatus,
    args.issueId,
    args.vsoAccount,
    args.product,
    args.agentTypes,
    args.appTypes,
    args.applications,
    args.platforms,
    args.licenses,
    args.authTypes,
    args.channel,
    args.customerTypes,
    args.ring,
    args.promptLanguages,
    args.userId,
    args.range,
    args.hasVerbatim,
    args.hasUserConsent,
    args.isCooked,
    args.isApology,
    args.searchText,
    args.searchTextPrefix,
    args.tenantIds,
    args.groundedPrompts,
    args.thumbs,
    args.invocationType,
    args.triggeredSkill,
    args.hasCitation,
    args.hasEntityCard,
    args.hitAvalon,
    args.priority,
    args.isSTCAChina,
    args.hasErrorMessages,
    args.isTopi18N,
    args.responseHeroType,
    args.responseLinkType,
    args.semanticSearchType,
    args.refreshTicker,
    args.experienceType,
    args.hasConnector,
    args.hasMessageExtension,
    args.hasGPTExtension,
    args.hasCopilotExtensionIds,
    args.optionsSets,
    args.errorCode,
    args.isGCIntent,
    args.hasConnectorResult,
    args.dSATStatus,
    args.customTags,
    args.dSATAssignedTo,
    args.sliceIds,
    args.copilotExtensionIds,
    args.flights,
    args.subMenu,
    args.topIssueBatchId,
    teamListSnapshot.status,
    args.meetingScenarios,
    args.hasScreenshot,
    args.chatMode,
  ])

  const navigateByTeamName = React.useCallback(
    (teamName: string, additionalArgs?: Partial<ITeamViewRouteArgs>) => {
      if (!TeamRoute.navigator.isCurrent()) return
      const defaultArgs = generateDefaultArg(additionalArgs?.subMenu ?? TeamViewSubMenuIds.UnRootCaused, args.product)
      TeamRoute.navigator.navigate({
        ...defaultArgs,
        ...(pickBy(additionalArgs ?? {}, (value) => !isNil(value)) as Partial<ITeamViewRouteArgs>),
        teamName: teamName, // only teamName should not be overridden
      })
    },
    [args.product],
  )

  React.useEffect(() => {
    useGlobalStore.getState().team.prepareTeamList(productId)
  }, [productId])

  React.useEffect(() => {
    if (teamListSnapshot.status !== 'done') return

    useTeamViewStore.getState().teams.syncLastSelectedTeamFromStorage(productId)
    const _lastSelectedTeam = useTeamViewStore.getState().teams.lastSelectedTeam
    if (!args.teamName && _lastSelectedTeam) {
      // navigate to last selected team from storage
      useTeamViewStore.getState().teams.setLastSelectedTeam(_lastSelectedTeam, true, productId)
      navigateByTeamName(_lastSelectedTeam, argsRef.current)
      return
    } else if (args.teamName) {
      // set last selected team from args
      useTeamViewStore.getState().teams.setLastSelectedTeam(args.teamName, true, productId)
      navigateByTeamName(args.teamName, argsRef.current)
      return
    } else if (
      teamListSnapshot.status === 'done' &&
      teamListSnapshot.data.length > 0 &&
      teamListSnapshot.data[0]?.name &&
      !argsRef.current.teamName
    ) {
      // navigate to first team if team name is not found
      useTeamViewStore.getState().teams.setLastSelectedTeam(teamListSnapshot.data[0].name, true, productId)
      navigateByTeamName(teamListSnapshot.data[0].name, argsRef.current)
    }

    return () => {
      // reset team store
      useTeamViewStore.getState().toggleTicketDetail(false)
      useTeamViewStore.getState().toggleColumnSetting(false)
      useTeamViewStore.getState().toggleFilterPanel(false)
    }
  }, [navigateByTeamName, productId, args.teamName, teamListSnapshot.status, teamListSnapshot.data])

  return (
    <TelemetryScope scope="TeamScreen">
      {teamListSnapshot.status === 'done' ? <TeamScreenLayout /> : null}
    </TelemetryScope>
  )
})

TeamScreen.displayName = 'TeamScreen'
