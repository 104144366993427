import { Text, mergeClasses } from '@fluentui/react-components'
import { FC } from 'react'
import { Row, Spacer, Column } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { IActivityHistory } from '@copilot-dash/domain'

interface IProps {
  readonly activity?: IActivityHistory
}

export const AssignToFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  if (activity?.fieldDiffs?.AssignTo?.oldValue === activity?.fieldDiffs?.AssignTo?.newValue) return null

  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Assign To:</Text>
      </Column>
      {activity?.fieldDiffs?.AssignTo?.newValue && (
        <Text className={styles.tag} title={activity?.fieldDiffs?.AssignTo?.newValue}>
          {activity?.fieldDiffs?.AssignTo?.newValue}
        </Text>
      )}
      <Spacer width="4px" />
      {activity?.fieldDiffs?.AssignTo?.oldValue && (
        <Text className={mergeClasses(styles.tag, styles.deleted)} title={activity?.fieldDiffs?.AssignTo?.oldValue}>
          {activity?.fieldDiffs?.AssignTo?.oldValue}
        </Text>
      )}
    </Row>
  )
}
