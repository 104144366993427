import { FC, useState, useEffect, useCallback } from 'react'
import { mergeClasses } from '@fluentui/react-components'
import { useStyles } from './ActivityComment.styles'
import { Row, Column } from '../../../Layout'
import { useActivityHistoryPanelStore } from '../../store/store'
import { transformCommentValue } from '../../../DiscussionPanel/children/CommentUtil'
import { IsLexicalDataSchema } from '../../../../utils/IsLexicalDataSchema'
import { RenderContent } from '../../../../utils/RenderContent'

export const ActivityComment: FC = () => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)
  const activeAccount = app.auth.activeAccount
  const [renderCommentNewValue, setRenderCommentNewValue] = useState<string | undefined>()
  const [renderCommentOldValue, setRenderCommentOldValue] = useState<string | undefined>()

  const processedNewValue = selectedActivity?.fieldDiffs?.CommentContent?.newValue
  const processedOldValue = selectedActivity?.fieldDiffs?.CommentContent?.oldValue

  const handleUpdateCommentValue = useCallback(
    async (commentValue: string | undefined, setRenderCommentOValue: (value: string | undefined) => void) => {
      const processedOldValue = commentValue
      if (!processedOldValue || !activeAccount?.localAccountId) return

      const updatedValue = await transformCommentValue(processedOldValue, activeAccount?.localAccountId)
      setRenderCommentOValue(updatedValue)
    },
    [activeAccount?.localAccountId],
  )

  useEffect(() => {
    if (processedNewValue && !IsLexicalDataSchema(processedNewValue)) {
      handleUpdateCommentValue(processedNewValue, setRenderCommentNewValue)
    }
  }, [handleUpdateCommentValue, processedNewValue])

  useEffect(() => {
    if (processedOldValue && !IsLexicalDataSchema(processedOldValue)) {
      handleUpdateCommentValue(processedOldValue, setRenderCommentOldValue)
    }
  }, [handleUpdateCommentValue, processedOldValue])

  //Case1: both are markdown format or there is just a new value and it is markdown
  if (
    (processedNewValue &&
      !IsLexicalDataSchema(processedNewValue) &&
      processedOldValue &&
      !IsLexicalDataSchema(processedOldValue)) ||
    (processedNewValue && !IsLexicalDataSchema(processedNewValue) && processedOldValue === '')
  ) {
    return (
      <Column fill>
        {renderCommentNewValue && (
          <Row className={styles.messageContainer}>
            <RenderContent
              rawComment={processedNewValue}
              renderCommentValue={renderCommentNewValue}
              markdownStyle={styles.markdown}
            />
          </Row>
        )}
        {renderCommentOldValue && (
          <Row className={styles.messageContainer}>
            <RenderContent
              rawComment={processedOldValue}
              renderCommentValue={renderCommentOldValue}
              markdownStyle={mergeClasses(styles.markdown, styles.deleted)}
              deletedStyle={styles.deleted}
              isOldComment={true}
            />
          </Row>
        )}
      </Column>
    )
  }

  //Case2: the old comment was markdown, the new one lexical format
  if (
    processedNewValue &&
    IsLexicalDataSchema(processedNewValue) &&
    processedOldValue &&
    !IsLexicalDataSchema(processedOldValue)
  ) {
    return (
      <Column fill>
        {processedNewValue && (
          <Row className="lexical">
            <RenderContent
              rawComment={processedNewValue}
              renderCommentValue={renderCommentNewValue}
              isLexicalData={true}
            />
          </Row>
        )}
        {renderCommentOldValue && (
          <Row className={styles.messageContainer}>
            <RenderContent
              rawComment={processedOldValue}
              renderCommentValue={renderCommentOldValue}
              markdownStyle={mergeClasses(styles.markdown, styles.deleted)}
              deletedStyle={styles.deleted}
              isOldComment={true}
            />
          </Row>
        )}
      </Column>
    )
  }

  //Case3: both are lexical format
  return (
    <Column>
      {processedNewValue && (
        <Row className="lexical">
          <RenderContent
            rawComment={processedNewValue}
            renderCommentValue={renderCommentNewValue}
            isLexicalData={true}
          />
        </Row>
      )}
      {processedOldValue && (
        <Row className="lexical">
          <RenderContent
            rawComment={processedOldValue}
            renderCommentValue={renderCommentOldValue}
            isLexicalData={true}
            deletedStyle={styles.deleted}
            isOldComment={true}
          />
        </Row>
      )}
    </Column>
  )
}
