import { ITelemetryMetric } from '@copilot-dash/domain'
import { JsonView } from '../../../../../../../components/JsonView/JsonView'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardBodyRaw({ metric }: IProps) {
  return <JsonView data={metric.raw} />
}
