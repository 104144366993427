import { IUserpData } from '@copilot-dash/domain'
import { makeStyles, Tab, TabList, TabListProps } from '@fluentui/react-components'

interface IProps {
  readonly selected: number
  readonly definitions: IUserpData[]
  readonly onSelect: (index: number) => void
}

export function UserpSearchNavigator({ selected, definitions, onSelect }: IProps) {
  const styles = useStyles()

  const handleOnSelect: TabListProps['onTabSelect'] = (event, data) => {
    const value = data.value
    if (typeof value === 'number') {
      onSelect(value)
    }
  }

  return (
    <TabList className={styles.root} selectedValue={selected} vertical onTabSelect={handleOnSelect}>
      {definitions.map((item, index) => (
        <Tab key={index} value={index} title={item.title}>
          {item.title}
        </Tab>
      ))}
    </TabList>
  )
}

const useStyles = makeStyles({
  root: {
    width: '200px',
    padding: '16px 0px',

    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})
