import { PromiseSnapshot } from '@copilot-dash/core'
import { IActivityHistory } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { ActivityHistoryConverter } from './ActivityHistoryConverter'
import { isEmpty } from 'lodash'

export function getTicketActivityHistory(
  context: IDashStoreContext,
  ticketId: string,
  forceRequest?: boolean,
): PromiseSnapshot<IActivityHistory[]> {
  return context.getOrFetch({
    get: (state) => {
      return forceRequest ? undefined : state.tickets[ticketId]?.activityHistory
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.activityHistory = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getActivityHistory(ticketId)

      return response.activityHistories
        .map(ActivityHistoryConverter.getActivityHistories)
        .filter(shouldIncludeActivity)
        .sort((a, b) => b.revision - a.revision)
    },
  })
}

function shouldIncludeActivity(activity: IActivityHistory): boolean {
  if (activity.activity === 'FeedbackCreated' || activity.activity === 'FeedbackCooked') return true
  if (activity.activity === 'Unset') return false
  if (isEmpty(activity.fieldDiffs)) return false

  // Check if all fields in ActivityFieldDiff are undefined
  const allFieldsUndefined = Object.values(activity.fieldDiffs!).every(
    (field) => field?.newValue === undefined && field?.oldValue === undefined,
  )

  return !allFieldsUndefined
}
