import { IFilterOption } from '../../Filter/IFilterOption.types'
import { UtteranceGroups } from '@copilot-dash/domain'

const getOptionSetUtteranceGroup = (): IFilterOption[] => {
  return Object.values(UtteranceGroups).map((utteranceGroup) => ({
    key: utteranceGroup,
    text: utteranceGroup,
  }))
}

export const OptionSetUtteranceGroup = getOptionSetUtteranceGroup()
