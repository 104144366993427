import {
  INewTicketData,
  InvocationSlicer,
  ProductName,
  SearchTextPrefixType,
  TeamId,
  TicketAuthType,
  TicketChatMode,
  TicketDSATStatusType,
  TicketRingType,
  UtteranceGroups,
} from '@copilot-dash/domain'

import { TimeRange } from '@copilot-dash/core'

export const BasicSearchTicketArgsNames = {
  id: 'id',
  order: 'order',
  isAIF: 'isAIF',
  userId: 'userId',
  tenantIds: 'tenantIds',
  range: 'range',
  product: 'product',
  applications: 'applications',
  channel: 'channel',
  ring: 'ring',
  thumbs: 'thumbs',
  isCooked: 'isCooked',
  licenses: 'licenses',
  authTypes: 'authTypes',
  platforms: 'platforms',
  priority: 'priority',
  searchText: 'searchText',
  searchTextPrefix: 'searchTextPrefix',
  dSATStatus: 'dSATStatus',
  customTags: 'customTags',
  dSATAssignedTo: 'dSATAssignedTo',
  invocationSlicers: 'invocationSlicers',
  meetingScenarios: 'meetingScenarios',
  hasScreenshot: 'hasScreenshot',
  optionsSets: 'optionsSets',
  copilotExtensionIds: 'copilotExtensionIds',
  sliceIds: 'sliceIds',
  flights: 'flights',
  chatMode: 'chatMode',
  utteranceGroups: 'utteranceGroups',
  scenarioSlicers: 'scenarioSlicers',
} as const

export const TagMetaDataSearchTicketArgsNames = {
  customerTypes: 'customerTypes',
  promptLanguages: 'promptLanguages',
  hasVerbatim: 'hasVerbatim',
  invocationType: 'invocationType',
  isApology: 'isApology',
  hasCitation: 'hasCitation',
  hasEntityCard: 'hasEntityCard',
  hitAvalon: 'hitAvalon',
  hasUserConsent: 'hasUserConsent',
  groundedPrompts: 'groundedPrompts',
  isSTCAChina: 'isSTCAChina',
  hasErrorMessages: 'hasErrorMessages',
  isTopi18N: 'isTopi18N',
  responseHeroType: 'responseHeroType',
  responseLinkType: 'responseLinkType',
  semanticSearchType: 'semanticSearchType',
  experienceType: 'experienceType',
  triggeredSkill: 'triggeredSkill',
  hasGPTExtension: 'hasGPTExtension',
  hasConnector: 'hasConnector',
  hasMessageExtension: 'hasMessageExtension',
  hasCopilotExtensionIds: 'hasCopilotExtensionIds',
  errorCode: 'errorCode',
  isGCIntent: 'isGCIntent',
  hasConnectorResult: 'hasConnectorResult',
  agentTypes: 'agentTypes',
  appTypes: 'appTypes',
} as const

export const TeamViewSearchTicketArgsNames = {
  ticketStatus: 'ticketStatus',
  teamName: 'teamName',
  issueId: 'issueId',
  vsoAccount: 'vsoAccount',
  ticketId: 'ticketId',
  recommendedRootCauseId: 'recommendedRootCauseId',
  clusteringIssueId: 'clusteringIssueId',
  batchId: 'batchId',
  teamId: 'teamId',
} as const

export interface ISearchTicketArgs {
  /**
   * Basic search
   */
  readonly [BasicSearchTicketArgsNames.id]?: Array<string>
  readonly [BasicSearchTicketArgsNames.order]?: Array<string>
  readonly [BasicSearchTicketArgsNames.isAIF]?: boolean
  readonly [BasicSearchTicketArgsNames.userId]?: Array<string>
  readonly [BasicSearchTicketArgsNames.tenantIds]?: Array<string>
  readonly [BasicSearchTicketArgsNames.range]?: TimeRange
  readonly [BasicSearchTicketArgsNames.product]?: ProductName
  readonly [BasicSearchTicketArgsNames.applications]?: Array<string>
  readonly [BasicSearchTicketArgsNames.channel]?: Array<string>
  readonly [BasicSearchTicketArgsNames.ring]?: Array<TicketRingType>
  readonly [BasicSearchTicketArgsNames.thumbs]?: Array<string>
  readonly [BasicSearchTicketArgsNames.isCooked]?: boolean | null
  readonly [BasicSearchTicketArgsNames.licenses]?: Array<string>
  readonly [BasicSearchTicketArgsNames.authTypes]?: Array<TicketAuthType>
  readonly [BasicSearchTicketArgsNames.platforms]?: Array<string>
  readonly [BasicSearchTicketArgsNames.priority]?: Array<string>
  readonly [BasicSearchTicketArgsNames.dSATStatus]?: Array<TicketDSATStatusType>
  readonly [BasicSearchTicketArgsNames.customTags]?: Array<string>
  readonly [BasicSearchTicketArgsNames.dSATAssignedTo]?: string
  readonly [BasicSearchTicketArgsNames.invocationSlicers]?: Array<InvocationSlicer>
  readonly [BasicSearchTicketArgsNames.meetingScenarios]?: Array<string>
  readonly [BasicSearchTicketArgsNames.hasScreenshot]?: boolean | null
  readonly [BasicSearchTicketArgsNames.searchText]?: string
  readonly [BasicSearchTicketArgsNames.searchTextPrefix]?: SearchTextPrefixType
  readonly [BasicSearchTicketArgsNames.optionsSets]?: string
  readonly [BasicSearchTicketArgsNames.sliceIds]?: string
  readonly [BasicSearchTicketArgsNames.copilotExtensionIds]?: string
  readonly [BasicSearchTicketArgsNames.flights]?: string
  readonly [BasicSearchTicketArgsNames.chatMode]?: Array<TicketChatMode>
  readonly [BasicSearchTicketArgsNames.utteranceGroups]?: Array<UtteranceGroups>
  readonly [BasicSearchTicketArgsNames.scenarioSlicers]?: Array<string>

  /**
   * Tag Meta Data
   */
  readonly [TagMetaDataSearchTicketArgsNames.agentTypes]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.appTypes]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.customerTypes]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.promptLanguages]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasVerbatim]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.invocationType]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.isApology]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasCitation]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasEntityCard]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hitAvalon]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasUserConsent]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.groundedPrompts]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.isSTCAChina]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasErrorMessages]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.isTopi18N]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.responseHeroType]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.responseLinkType]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.semanticSearchType]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.experienceType]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.triggeredSkill]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasGPTExtension]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasConnector]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasMessageExtension]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasCopilotExtensionIds]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.errorCode]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.isGCIntent]?: Array<string>
  readonly [TagMetaDataSearchTicketArgsNames.hasConnectorResult]?: Array<string>

  /**
   * Team View
   */
  readonly [TeamViewSearchTicketArgsNames.ticketStatus]?: string
  readonly [TeamViewSearchTicketArgsNames.teamName]?: string
  readonly [TeamViewSearchTicketArgsNames.issueId]?: string
  readonly [TeamViewSearchTicketArgsNames.vsoAccount]?: string
  readonly [TeamViewSearchTicketArgsNames.ticketId]?: string
  readonly [TeamViewSearchTicketArgsNames.recommendedRootCauseId]?: string
  readonly [TeamViewSearchTicketArgsNames.clusteringIssueId]?: string
  readonly [TeamViewSearchTicketArgsNames.batchId]?: string
  readonly [TeamViewSearchTicketArgsNames.teamId]?: TeamId
}

export interface ISearchTicketOptions extends ISearchTicketArgs {
  readonly client?: Array<string>
  readonly defaultRange?: TimeRange
  readonly queryId?: string
  /**
   * search parameters
   */
  readonly offset: number
  readonly count: number
}

export interface ISearchTicketResult {
  readonly total: number
  readonly tickets: Array<INewTicketData>
  readonly hasMore: boolean
}
