import { ApiV2TicketSource, ApiV2TicketSourceComplianceLevel, ApiV2TicketSourceType } from '@copilot-dash/api'
import { ITicketSource, ITicketSourceData, ITicketTurnSource } from '@copilot-dash/domain'

export function convertToTicketSource(sources: ApiV2TicketSource[]): ITicketSource {
  return {
    ocv: getOcv(),
    turns: getTurns(),
    endpoint: 'CopilotDashV2',
    raw: sources,
  }

  function getOcv(): ITicketSourceData | undefined {
    const ocv = find('OCV')[0]
    return ocv ? toSource(ocv) : undefined
  }

  function getTurns(): { [messageId: string]: ITicketTurnSource } {
    const map: Record<keyof ITicketTurnSource, ApiV2TicketSource[]> = {
      conversation: find('Conversation'),
      conversationGroup1: find('Conversation', 1),
      conversationGroup2: find('Conversation', 2),
      conversationGroup3: find('Conversation', 3),
      conversationGroup4: find('Conversation', 4),
      userpGroup1: find('USERP', 1),
      userpGroup2: find('USERP', 2),
      userpGroup3: find('USERP', 3),
      userpGroup4: find('USERP', 4),
      substrateSearchOnlineGroup1: find('SubstrateSearchOnline', 1),
      substrateSearchOnlineGroup2: find('SubstrateSearchOnline', 2),
      substrateSearchOnlineGroup3: find('SubstrateSearchOnline', 3),
      substrateSearchOnlineGroup4: find('SubstrateSearchOnline', 4),
      substrateSearchOfflineGroup1: find('SubstrateSearchOffline', 1),
      substrateSearchOfflineGroup2: find('SubstrateSearchOffline', 2),
      substrateSearchOfflineGroup3: find('SubstrateSearchOffline', 3),
      substrateSearchOfflineGroup4: find('SubstrateSearchOffline', 4),
      substrateSearchLog: find('SubstrateSearchInfo'),
      substrateSearchLatencyLog: find('BizChat3SLatency'),
      performanceLog: find('BizchatPerformanceEventV4'),
      llmLog: find('LLM'),
      augLoopLog: find('AugLoop'),
      stateDurationLog: find('SydneyTuringBotMonitoredScope'),
      traceLog: find('SydneyTuringBotTraceMDS'),
      extensibility: find('SydneyTuringBotChatMessageEventMDS'),
    }
    const turns: { [messageId: string]: ITicketTurnSource } = {}
    Object.entries(map).forEach((entry) => {
      const key = entry[0] as keyof ITicketTurnSource
      const items = entry[1]

      for (const item of items) {
        if (item.relatedId) {
          turns[item.relatedId] = {
            [key]: toSource(item),
            ...turns[item.relatedId],
          }
        }
      }
    })

    return turns
  }

  function find(type: ApiV2TicketSourceType, level?: ApiV2TicketSourceComplianceLevel): ApiV2TicketSource[] {
    if (level === undefined) {
      return sources.filter((source) => source.type === type) ?? []
    } else {
      return sources.filter((source) => source.type === type && source.complianceLevel === level) ?? []
    }
  }

  function toSource(raw: ApiV2TicketSource): ITicketSourceData {
    return {
      blobUrl: raw.url,
      raw: raw,
    }
  }
}
