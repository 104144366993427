import { makeStyles, tokens } from '@fluentui/react-components'
import { Column } from '../../../../../components/Layout'
import { TicketScreenTelemetryBar } from './TicketScreenTelemetryBar'
import { TicketScreenTelemetryBody } from './TicketScreenTelemetryBody'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function TicketScreenTelemetry({ ticketId, messageId }: IProps) {
  const styles = useStyles()

  return (
    <Column className={styles.root} fill>
      <TicketScreenTelemetryBar />
      <TicketScreenTelemetryBody ticketId={ticketId} messageId={messageId} />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    '& > *:first-child': {
      flex: '0 0 auto',
      border: `1px solid ${tokens.colorNeutralStroke3}`,
      borderBottom: 'none',
    },

    '& > *:last-child': {
      flex: '1 1 0',
      border: `1px solid ${tokens.colorNeutralStroke3}`,
    },
  },
})
