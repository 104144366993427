import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoAugLoopLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getAugLoopLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoAugLoopLogItem[]> {
  return context.getOrFetch<IKustoAugLoopLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.augLoopLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.augLoopLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const logs = await doFetchFromV2()

      if (logs.length > 0) {
        return logs
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.AugLoop)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.AugLoop })
    },
  })

  async function doFetchFromV2(): Promise<IKustoAugLoopLogItem[]> {
    const ticketSource = await getTicketSource(context, ticketId).promise
    const source = ticketSource.turns[messageId]?.augLoopLog
    if (source) {
      const results = await context.api.copilotDash.getTicketBlob(ticketId, source.blobUrl).asAugLoopLog()
      return results
    }

    return []
  }
}
