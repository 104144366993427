import { makeStyles } from '@fluentui/react-components'
import { AnimatedTabBody } from '../../../../../components/AnimatedTabBody/AnimatedTabBody'
import { TicketScreenTelemetryTab } from '../../../store/IState'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { JsonView } from './tabs/json/JsonView'
import { MetricView } from './tabs/metrics/MetricView'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function TicketScreenTelemetryBody({ ticketId, messageId }: IProps) {
  const styles = useStyles()
  const tabs = TicketScreenStore.use((state) => state.supportedTelemetryTabs)
  const activeTab = TicketScreenStore.use((state) => state.selectedTelemetryTab)
  const activeTabIndex = activeTab ? tabs.indexOf(activeTab) : -1

  return (
    <AnimatedTabBody tabs={tabs} className={styles.root} activeTabIndex={activeTabIndex}>
      {(tab) => {
        switch (tab) {
          case TicketScreenTelemetryTab.Metrics:
            return <MetricView ticketId={ticketId} messageId={messageId} />
          case TicketScreenTelemetryTab.JSON:
            return <JsonView ticketId={ticketId} messageId={messageId} />
        }
      }}
    </AnimatedTabBody>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
})
