import { IState, TicketScreenOutlineTab, TicketScreenTelemetryTab } from './IState'

interface IParams {
  readonly ticketId: string
}

export function createState({ ticketId }: IParams): IState {
  return {
    ticketId,
    supportedOutlineTabs: Object.values(TicketScreenOutlineTab),
    supportedMainTabs: [],
    supportedKustoTabs: [],
    supportedTelemetryTabs: Object.values(TicketScreenTelemetryTab),
    selectedOutlineTab: TicketScreenOutlineTab.Conversation,
    selectedMainTab: undefined,
    selectedKustoTab: undefined,
    selectedTelemetryTab: TicketScreenTelemetryTab.Metrics,
    selectedTurnId: undefined,
    selectedScopeId: undefined,
    isOpenDiscussionPanel: false,
    isOpenActivityDiscussionPanel: false,
    activeDrawer: undefined,
  }
}
