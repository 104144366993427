import { TicketChatMode } from '@copilot-dash/domain'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetChatMode } from '../../config/OptionSetChatMode'
import { z } from 'zod'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: TicketChatMode[] | undefined
  onChangeValue: (value: TicketChatMode[]) => void
}

export const ChatModeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const optionsList = OptionSetChatMode

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-chatModeFilter'}
      filterType={getTagDisplayName('chatMode')}
      isGroup={false}
      optionsList={optionsList}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const parseResult = z.array(z.nativeEnum(TicketChatMode)).safeParse(item)
        if (parseResult.success) {
          onChangeValue(parseResult.data)
        }
      }}
    />
  )
}
