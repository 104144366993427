import { Divider } from '@fluentui/react-components'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { useState } from 'react'
import { Column } from '../../Layout'
import { FloatingLinkEditorPlugin } from '../editor-plugins/floatingLinkEditorPlugin/FloatingLinkEditorPlugin'
import { ImportHTMLPlugin } from '../editor-plugins/htmlPlugin/importHTML'
import { ImagesPlugin } from '../editor-plugins/imagePlugin/ImagesPlugin'
import { PasteImageUploadPlugin } from '../editor-plugins/imagePlugin/PasteImageUploadPlugin'
import { KeyboardPlugin } from '../editor-plugins/keyboard/KeyboardPlugin'
import { LinkPlugin } from '../editor-plugins/linkPlugin/LinkPlugin'
import { MentionsPlugin } from '../editor-plugins/mentionsPlugin/MentionsPlugin'
import { ToolbarPlugin } from '../editor-plugins/toolbarPlugin/ToolbarPlugin'
import { placeholder } from '../utils/lexical'
import { MATCHERS } from '../utils/url'
import { useStyles } from './EditorLayout.styles'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { UploadLoadingPlugin } from '../editor-plugins/imagePlugin/UploadLoadingPlugin'

export function EditorLayout() {
  const styles = useStyles()
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false)
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }
  const [editor] = useLexicalComposerContext()

  const handleOnclick = () => {
    if (!isLinkEditMode) {
      editor.focus()
    }
  }

  return (
    <Column className={styles.editorContainer} onClick={handleOnclick}>
      <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
      <Divider className={styles.divider} />
      <Column className="lexical">
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              ref={onRef}
              className={styles.editorInput}
              aria-placeholder={placeholder}
              placeholder={<div className={styles.editorPlaceholder}>{placeholder}</div>}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <AutoFocusPlugin />
        <ListPlugin />
        <MentionsPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
        <LinkPlugin />
        {floatingAnchorElem && (
          <FloatingLinkEditorPlugin
            anchorElem={floatingAnchorElem}
            isLinkEditMode={isLinkEditMode}
            setIsLinkEditMode={setIsLinkEditMode}
          />
        )}
        <ImportHTMLPlugin />
        <ImagesPlugin />
        <PasteImageUploadPlugin />
      </Column>
      <KeyboardPlugin />
      <UploadLoadingPlugin />
    </Column>
  )
}
