import { Route } from 'react-router-dom'
import { TicketScreen } from '../../../screens/ticket/TicketScreen'
import { createNavigator } from '../../navigator/createNavigator'
import { TicketRouteArgs } from './TicketRouteArgs'
import { TicketRouteMapper } from './TicketRouteMapper'
import { TicketRoutePage } from './TicketRoutePage'

export function TicketRoute() {
  return <Route id={ID} path={PATH} element={<TicketRoutePage />} />
}

const ID = 'ticket'
const PATH = '/ticket/:id'

TicketRoute.navigator = Object.assign(createNavigator(ID, PATH, TicketRouteMapper), {
  navigateToTicketModal: (args: TicketRouteArgs) => {
    app.dialog.open({
      position: 'bottom',
      content: <TicketScreen ticketId={args.id} />,
    })
  },
})
