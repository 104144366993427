import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawUserpGroup1 } from '../actions-raw-ticket-userp/getRawUserpGroup1'
import { getRawUserpGroup2 } from '../actions-raw-ticket-userp/getRawUserpGroup2'
import { getRawUserpGroup3 } from '../actions-raw-ticket-userp/getRawUserpGroup3'
import { getRawUserpGroup4 } from '../actions-raw-ticket-userp/getRawUserpGroup4'
import { IUserpData } from '@copilot-dash/domain'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { TicketError } from '@copilot-dash/error'

export function getTicketTurnUserpContext(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IUserpData[]> {
  return context.getOrFetch<IUserpData[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.userpGroup
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.userpGroup = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<IUserpData[]> {
    return await fetchFromUserp()
  }

  async function fetchFromUserp(): Promise<IUserpData[]> {
    const source = await getTicketSource(context, ticketId).promise
    const permission = context.hasTier2

    const sourceBlobUrl1 = source.turns[messageId]?.userpGroup1
    const sourceBlobUrl2 = source.turns[messageId]?.userpGroup2
    const sourceBlobUrl3 = source.turns[messageId]?.userpGroup3
    const sourceBlobUrl4 = source.turns[messageId]?.userpGroup4

    const group1Promise = getRawUserpGroup1(context, ticketId, messageId).promise
    const group2Promise = getRawUserpGroup2(context, ticketId, messageId).promise
    const group3Promise = getRawUserpGroup3(context, ticketId, messageId).promise
    const group4Promise = permission ? getRawUserpGroup4(context, ticketId, messageId).promise : undefined

    const group1Data = await group1Promise.catch((e) => undefined)
    const group2Data = await group2Promise.catch((e) => undefined)
    const group3Data = await group3Promise.catch((e) => undefined)
    const group4Data = await group4Promise?.catch((e) => undefined)

    const group1Error = await group1Promise.then(() => undefined).catch((e) => e)
    const group2Error = await group2Promise.then(() => undefined).catch((e) => e)
    const group3Error = await group3Promise.then(() => undefined).catch((e) => e)
    const group4Error = await group4Promise?.then(() => undefined).catch((e) => e)

    const userpGroup: IUserpData[] = []

    if (sourceBlobUrl1) {
      userpGroup.push({
        title: 'Request Data',
        data: group1Data,
        error: group1Error,
      })
    }
    if (sourceBlobUrl2) {
      userpGroup.push({
        title: 'LU Data',
        data: group2Data,
        error: group2Error,
      })
    }
    if (sourceBlobUrl3) {
      userpGroup.push({
        title: 'Response Metadata',
        data: group3Data,
        error: group3Error,
      })
    }
    if (sourceBlobUrl4) {
      userpGroup.push({
        title: 'Search Entity Data',
        data: group4Data,
        error: group4Error,
      })
    }
    if (userpGroup.length > 0) {
      return userpGroup
    }
    await assertTicketTurnExists(context, ticketId, messageId)
    throw TicketError.create('NoUserp', { ticketId, ticketMessageId: messageId })
  }
}
