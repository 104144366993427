import { makeStyles } from '@fluentui/react-components'
import { AnimatedTabBody } from '../../../../components/AnimatedTabBody/AnimatedTabBody'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenMainTab } from '../../store/IState'
import { ConversationData } from './ticket-conversation/ConversationData'
import { TicketScreenKustoLogs } from './ticket-kusto/TicketScreenKustoLogs'
import { TicketScreenSymptomReport } from './ticket-symptom-report/TicketScreenSymptomReport'
import { TicketScreenTelemetry } from './ticket-telemetry/TicketScreenTelemetry'
import { UserpSearchData } from './ticket-userp/UserpSearchData'

export function TicketScreenMainBody() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)
  const tabs = TicketScreenStore.use((state) => state.supportedMainTabs)
  const activeTab = TicketScreenStore.use((state) => state.selectedMainTab)
  const activeTabIndex = activeTab ? tabs.indexOf(activeTab) : -1

  return (
    <AnimatedTabBody tabs={tabs} className={styles.root} activeTabIndex={activeTabIndex}>
      {(tab) => {
        switch (tab) {
          case TicketScreenMainTab.SymptomReport:
            return <TicketScreenSymptomReport />
          case TicketScreenMainTab.ConversationData:
            return selectedTurnId && <ConversationData ticketId={ticketId} selectedTurnId={selectedTurnId} />
          case TicketScreenMainTab.Telemetry:
            return selectedTurnId && <TicketScreenTelemetry ticketId={ticketId} messageId={selectedTurnId} />
          case TicketScreenMainTab.UserpSearchData:
            return selectedTurnId && <UserpSearchData ticketId={ticketId} selectedTurnId={selectedTurnId} />
          case TicketScreenMainTab.KustoLog:
            return <TicketScreenKustoLogs />
        }
      }}
    </AnimatedTabBody>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
})
