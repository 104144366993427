import {
  IActivityHistory,
  activityHistoryRootCauseContextSchema,
  ticketIssueDataSchema,
  ActivityHistoryRootCauseContext,
  TicketIssueData,
  ActivityHistoryType,
  activityHistoryTypeSchema,
  SystemType,
  systemTypeSchema,
  ActivityFieldDiff,
} from '@copilot-dash/domain'
import { ActivityHistoryDiff, ApiFieldDiff } from '@copilot-dash/api'
import { z } from 'zod'
import { isNil } from 'lodash'

export class ActivityHistoryConverter {
  static readonly getActivityHistories = (data: ActivityHistoryDiff): IActivityHistory => {
    const diff = data.fieldDiffs
    const activity = this.safeParseActivityHistory(data.activity)
    const parsedDiff: ActivityFieldDiff = {}
    if (diff) {
      Object.keys(diff).forEach((key) => {
        const fieldDiff = diff[key]
        if (fieldDiff)
          switch (key) {
            case 'state':
              parsedDiff.State = this.parseStringFieldDiff(fieldDiff)
              break
            case 'priority':
              parsedDiff.Priority = this.parseNumberOrStringFieldDiff(fieldDiff)
              break
            case 'teamArea':
              parsedDiff.TeamArea = this.parseStringFieldDiff(fieldDiff)
              break
            case 'teamId':
              parsedDiff.TeamId = this.parseNumberOrStringFieldDiff(fieldDiff)
              break
            case 'assignTo':
              parsedDiff.AssignTo = this.parseStringFieldDiff(fieldDiff)
              break
            case 'issueList':
              parsedDiff.IssueList = this.parseTicketIssueDataListFieldDiff(fieldDiff)
              break
            case 'customTags':
              parsedDiff.CustomTags = this.parseStringListFieldDiff(fieldDiff)
              break
            case 'rootCauseActiveContext':
              parsedDiff.RootCauseActiveContext = this.parseActivityHistoryRootCauseContextListFieldDiff(fieldDiff)
              break
            case 'commentId':
              parsedDiff.CommentId = this.parseStringFieldDiff(fieldDiff)
              break
            case 'commentContent':
              parsedDiff.CommentContent = this.parseStringFieldDiff(fieldDiff)
              break
          }
      })
    }
    return {
      revision: data.revision,
      activityId: data.activityId,
      ticketId: data.ticketId,
      timestamp: data.timestamp,
      activity: activity,
      actor: data.actor,
      userId: data.actor === 'user' ? data.userId : undefined,
      system: data.actor === 'system' ? this.safeParseSystemType(data.system) : undefined,
      fieldDiffs: parsedDiff,
    }
  }

  private static parseStringFieldDiff(fieldDiff: ApiFieldDiff) {
    const parsedField: { newValue?: string; oldValue?: string } = {}
    if (fieldDiff) {
      const newValue = this.deserializeString(fieldDiff.newValue)
      const oldValue = this.deserializeString(fieldDiff.oldValue)
      if (newValue) {
        parsedField.newValue = newValue
      }
      if (oldValue) {
        parsedField.oldValue = oldValue
      }
      if (newValue || oldValue) {
        return parsedField
      }
    }
    return undefined
  }

  private static parseNumberOrStringFieldDiff(fieldDiff: ApiFieldDiff) {
    const parsedField: { newValue?: string; oldValue?: string } = {}
    if (fieldDiff) {
      const newValue = this.deserializeNumber(fieldDiff.newValue)
      const oldValue = this.deserializeNumber(fieldDiff.oldValue)
      if (!isNil(newValue)) {
        parsedField.newValue = newValue.toString()
      }
      if (!isNil(oldValue)) {
        parsedField.oldValue = oldValue.toString()
      }
      const newValueString = this.deserializeString(fieldDiff.newValue)
      const oldValueString = this.deserializeString(fieldDiff.oldValue)
      if (!isNil(newValueString)) {
        parsedField.newValue = newValueString
      }
      if (!isNil(oldValueString)) {
        parsedField.oldValue = oldValueString
      }
      if (!isNil(newValue) || !isNil(oldValue)) {
        return parsedField
      }
    }
    return undefined
  }

  private static parseStringListFieldDiff(fieldDiff: ApiFieldDiff) {
    const parsedField: { newValue?: string[]; oldValue?: string[] } = {}
    if (fieldDiff) {
      const newValue = this.deserializeStringList(fieldDiff.newValue)
      const oldValue = this.deserializeStringList(fieldDiff.oldValue)
      if (!isNil(newValue)) {
        parsedField.newValue = newValue
      }
      if (!isNil(oldValue)) {
        parsedField.oldValue = oldValue
      }
      if (!isNil(newValue) || !isNil(oldValue)) {
        return parsedField
      }
    }
    return undefined
  }

  private static parseTicketIssueDataListFieldDiff(fieldDiff: ApiFieldDiff) {
    const parsedField: { newValue?: TicketIssueData[]; oldValue?: TicketIssueData[] } = {}
    if (fieldDiff) {
      const newValue = this.deserializeTicketIssueDataList(fieldDiff.newValue)
      const oldValue = this.deserializeTicketIssueDataList(fieldDiff.oldValue)
      if (!isNil(newValue)) {
        parsedField.newValue = newValue
      }
      if (!isNil(oldValue)) {
        parsedField.oldValue = oldValue
      }
      if (!isNil(newValue) || !isNil(oldValue)) {
        return parsedField
      }
    }
    return undefined
  }

  private static parseActivityHistoryRootCauseContextListFieldDiff(fieldDiff: ApiFieldDiff) {
    const parsedField: { newValue?: ActivityHistoryRootCauseContext[]; oldValue?: ActivityHistoryRootCauseContext[] } =
      {}
    if (fieldDiff) {
      const newValue = this.deserializeActivityHistoryRootCauseContextList(fieldDiff.newValue)
      const oldValue = this.deserializeActivityHistoryRootCauseContextList(fieldDiff.oldValue)
      if (!isNil(newValue)) {
        parsedField.newValue = newValue
      }
      if (!isNil(oldValue)) {
        parsedField.oldValue = oldValue
      }
      if (!isNil(newValue) || !isNil(oldValue)) {
        return parsedField
      }
    }
    return undefined
  }

  public static safeParseSystemType(value: unknown): SystemType {
    const result = systemTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'Unset'
    }
  }

  public static safeParseActivityHistory(value: unknown): ActivityHistoryType {
    const result = activityHistoryTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'Unset'
    }
  }

  private static deserializeString(value?: string): string | undefined {
    if (!value) return undefined
    const schema = z.string()

    try {
      return schema.parse(JSON.parse(value))
    } catch (e) {
      return value
    }
  }

  private static deserializeStringList(value?: string): string[] | undefined {
    if (!value) return undefined
    const schema = z.array(z.string())
    try {
      return schema.parse(JSON.parse(value))
    } catch (e) {
      return undefined
    }
  }

  private static deserializeNumber(value?: string): number | undefined {
    if (!value) return undefined
    const schema = z.number()
    try {
      return schema.parse(JSON.parse(value))
    } catch (e) {
      return undefined
    }
  }

  private static deserializeTicketIssueDataList(value?: string): TicketIssueData[] | undefined {
    if (!value) return undefined
    const schema = z.array(ticketIssueDataSchema)

    try {
      return schema.parse(JSON.parse(value))
    } catch (e) {
      return undefined
    }
  }

  private static deserializeActivityHistoryRootCauseContextList(
    value?: string,
  ): ActivityHistoryRootCauseContext[] | undefined {
    if (!value) return undefined
    const schema = z.array(activityHistoryRootCauseContextSchema)

    try {
      return schema.parse(JSON.parse(value))
    } catch (e) {
      return undefined
    }
  }
}
