import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { Row, Column, Spacer } from '../../../Layout'
import { useStyles } from './StatusField.styles'
import { useActivityHistoryPanelStore } from '../../store/store'
import { xor } from 'lodash'

export const ActivityUpdateCustomTags: FC = () => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)
  const AddedTags = useMemo(() => {
    if (selectedActivity?.fieldDiffs?.CustomTags) {
      const { oldValue, newValue } = selectedActivity.fieldDiffs.CustomTags
      if (oldValue && newValue) {
        const addedTags = xor(newValue, oldValue).filter((tag) => !oldValue.includes(tag))
        return addedTags
      } else if (newValue && newValue.length > 0) {
        return newValue
      }
    }
    return []
  }, [selectedActivity])

  const DeletedTags = useMemo(() => {
    if (selectedActivity?.fieldDiffs?.CustomTags) {
      const { oldValue, newValue } = selectedActivity.fieldDiffs.CustomTags
      if (oldValue && newValue) {
        const deletedTags = xor(newValue, oldValue).filter((tag) => !newValue.includes(tag))

        return deletedTags
      } else if (oldValue && oldValue.length > 0) {
        return oldValue
      }
    }
    return []
  }, [selectedActivity])

  if (AddedTags.length === 0 && DeletedTags.length === 0) return null

  return (
    <Row vAlign="center">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Tags:</Text>
      </Column>
      {AddedTags.length > 0 && (
        <Text style={{ width: 'fit-content' }}>
          {AddedTags.map((tag, idx) => (
            <Text key={idx} className={mergeClasses(styles.tag, styles.customTag)} title={tag}>
              {tag}{' '}
            </Text>
          ))}
        </Text>
      )}
      <Spacer width="4px" />
      {DeletedTags.length > 0 && (
        <Text className={styles.deleted}>
          {DeletedTags.map((tag, idx) => (
            <Text key={idx} className={mergeClasses(styles.tag, styles.customTag)} title={tag}>
              {tag}{' '}
            </Text>
          ))}
        </Text>
      )}
    </Row>
  )
}
