import { TelemetryScope } from '@copilot-dash/logger'
import { TicketScreenLayout } from './layout/TicketScreenLayout'
import { TicketScreenStore } from './TicketScreenStore'

interface IProps {
  readonly ticketId: string
}

export function TicketScreen({ ticketId }: IProps) {
  return (
    <TelemetryScope scope="TicketScreen" properties={{ ticketId }}>
      <TicketScreenStore key={ticketId} ticketId={ticketId}>
        <TicketScreenLayout />
      </TicketScreenStore>
    </TelemetryScope>
  )
}
