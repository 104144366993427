import { ApiUserpGroup1 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getRawUserpGroup1(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiUserpGroup1> {
  return context.getOrFetch<ApiUserpGroup1>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.rawUserpGroup1
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.rawUserpGroup1 = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceBlobUrl = source.turns[turnId]?.userpGroup1?.blobUrl

      if (sourceBlobUrl) {
        if (source.endpoint === 'ODS') {
          return await context.api.ods.getUserpGroup1(sourceBlobUrl)
        } else {
          return await context.api.copilotDash.getTicketBlob(ticketId, sourceBlobUrl).asUserpGroup1()
        }
      }

      await assertTicketTurnExists(context, ticketId, turnId)
      throw TicketError.create('NoUserp', { ticketId, ticketMessageId: turnId })
    },
  })
}
