import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { OptionSetUtteranceGroup } from '../../config/OptionSetUtteranceGroup'
import { getTagDisplayName } from '../../config/SupportedTags'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const UtteranceGroupFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const groupList = OptionSetUtteranceGroup

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-utteranceGroupFilter'}
        filterType={getTagDisplayName('utteranceGroups')}
        isGroup={true}
        optionsList={groupList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
        infoLabelContent={<>This field is computed offline and may have some delay. The SLA is 33 hours.</>}
      />
    </>
  )
}
