import { IActivityHistory, RootCauseActionType, ITeamData } from '@copilot-dash/domain'
import { xor, xorBy } from 'lodash'

function mapRootCauseActionType(value: string): string {
  switch (value) {
    case RootCauseActionType.UserNoAction:
      return 'no Action'
    case RootCauseActionType.UserRejected:
      return 'Rejected'
    case RootCauseActionType.UserConfirmed:
      return 'Confirmed'
    case RootCauseActionType.DirectLinked:
      return 'Direct Linked'
    case RootCauseActionType.DirectLinkedReviewed:
      return 'Direct Linked Reviewed'
    case RootCauseActionType.DirectLinkedRemoved:
      return 'Direct Linked Removed'
    default:
      return 'Unknown Action'
  }
}

export function summarizeActivity(activity: IActivityHistory, teams?: ITeamData[]): string {
  switch (activity.activity) {
    case 'AddComment':
      return 'Added a Comment.'
    case 'UpdateComment':
      return 'Edited a Comment.'
    case 'DeleteComment':
      return 'Deleted a Comment.'
    case 'TicketStatusUpdate':
    case 'WorkItemAutoTriage': {
      const summaries: string[] = []
      if (activity.fieldDiffs) {
        //State
        if (activity.fieldDiffs.State) {
          const { newValue } = activity.fieldDiffs.State
          if (newValue) {
            summaries.push(`Changed state to ${newValue}`)
          }
        }
        //TeamArea or TeamId
        if (activity.fieldDiffs.TeamArea) {
          const { newValue } = activity.fieldDiffs.TeamArea
          if (newValue) {
            summaries.push(`Changed team to ${newValue}`)
          }
        } else if (activity.fieldDiffs.TeamId) {
          const { newValue } = activity.fieldDiffs.TeamId
          if (teams && newValue) {
            const team = teams.find((team) => team.id === newValue.toString())
            if (team) {
              summaries.push(`Changed team to ${team.name}`)
            }
          }
        }
        //Priority
        if (activity.fieldDiffs.Priority) {
          const { newValue } = activity.fieldDiffs.Priority
          if (newValue) {
            summaries.push(`Changed priority to ${newValue}`)
          }
        }
        //Assign To
        if (activity.fieldDiffs.AssignTo) {
          const { oldValue, newValue } = activity.fieldDiffs.AssignTo
          if (newValue) {
            summaries.push(`Assigned ${newValue}`)
          } else if (oldValue) {
            summaries.push(`Unassigned ${oldValue}`)
          }
        }
        //Root Cause
        if (activity.fieldDiffs.IssueList) {
          const { oldValue, newValue } = activity.fieldDiffs.IssueList
          if (oldValue && newValue) {
            const addedIssues = xorBy(newValue, oldValue, 'IssueId').filter(
              (issue) => !oldValue.some((oldIssue) => oldIssue.IssueId === issue.IssueId),
            )
            const deletedIssues = xorBy(newValue, oldValue, 'IssueId').filter(
              (issue) => !newValue.some((newIssue) => newIssue.IssueId === issue.IssueId),
            )

            if (addedIssues.length > 0 && deletedIssues.length > 0) {
              summaries.push(`Changed Root Cause`)
            } else if (addedIssues.length > 0) {
              summaries.push(`Added Root Cause`)
            } else if (deletedIssues.length > 0) {
              summaries.push(`Deleted Root Cause`)
            }
          } else if (oldValue && oldValue.length > 0) {
            summaries.push(`Removed Root Cause`)
          } else if (newValue && newValue.length > 0) {
            summaries.push(`Added Root Cause`)
          }
        }

        if (summaries.length > 1) {
          return summaries[0] + ' and made fields changes.'
        }
      }

      return summaries.join(', and ') + '.'
    }
    case 'SetTicketRootCausingActions': {
      const summary: string[] = []

      if (activity.fieldDiffs) {
        if (activity.fieldDiffs.RootCauseActiveContext) {
          const { newValue } = activity.fieldDiffs.RootCauseActiveContext
          if (newValue && newValue.length > 0) {
            newValue.forEach((action) => {
              summary.push(`${mapRootCauseActionType(action.UserAction)} a Root Cause`)
            })
          }
        }
        return summary.join(', and ') + '.'
      }

      return ''
    }
    case 'WorkItemCreated':
      return 'Created work item.'
    case 'FeedbackCreated':
      return 'User Created Feedback.'
    case 'FeedbackCooked':
      return 'Completed Feedback Diagnostic Data Collection.'
    case 'UpdateCustomTags':
      if (activity.fieldDiffs) {
        if (activity.fieldDiffs.CustomTags) {
          const { oldValue, newValue } = activity.fieldDiffs.CustomTags
          if (oldValue && newValue) {
            const addedTags = xor(newValue, oldValue).filter((tag) => !oldValue.includes(tag))
            const deletedTags = xor(newValue, oldValue).filter((tag) => !newValue.includes(tag))

            if (addedTags.length > 0 && deletedTags.length > 0) {
              return `Updated Tags.`
            } else if (deletedTags.length > 0) {
              return `Removed ${deletedTags.length} Tag${deletedTags.length > 1 ? 's' : ''}.`
            } else if (addedTags.length > 0) {
              return `Added ${addedTags.length} Tag${addedTags.length > 1 ? 's' : ''}.`
            }
          } else if (oldValue && oldValue.length > 0) {
            return `Removed ${oldValue.length} Tag${oldValue.length > 1 ? 's' : ''}.`
          } else if (newValue && newValue.length > 0) {
            return `Added ${newValue.length} Tag${newValue.length > 1 ? 's' : ''}.`
          }
        }
      }
      return ''
    default:
      return ''
  }
}
