import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { Row, Spacer } from '../../../../../../../components/Layout'
import { CopyButton } from '../../../../../../../components/Copy/CopyButton'

interface IProps {
  readonly title: string
  readonly content: string
}

export function MetricCardJsonHeader({ title, content }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root} vAlign="center">
      <Text className={styles.title}>{title}</Text>
      <Spacer />
      {!!content && <CopyButton content={content} />}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    minHeight: '40px',
  },
  title: {
    color: tokens.colorPaletteGreenForeground3,
  },
  status: {
    padding: '16px',
  },
})
