import { PromiseSnapshot } from '@copilot-dash/core'
import { DefaultProductEndpoints, DefaultProducts } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV1Ticket } from '../actions-raw-ticket/getRawV1Ticket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getTicketEmail } from './getTicketEmail'

export function getTicketContext(context: IDashStoreContext, ticketId: string): PromiseSnapshot<object> {
  return context.getOrFetch<object>({
    get: (state) => {
      return state.tickets[ticketId]?.ticketContext
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.ticketContext = snapshot
    },
    fetch: async () => {
      return context.enableV2Endpoint ? fetchFromV2() : fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<object> {
    const ticketPromise = getRawV1Ticket(context, ticketId).promise
    const emailPromise = getTicketEmail(context, ticketId).promise

    const ticket = await ticketPromise
    const email = await emailPromise.catch((error) => String(error))
    const client = DefaultProductEndpoints.find((p) => p.name.toLowerCase() === ticket.clientName?.toLowerCase())
    const product = DefaultProducts.find((p) => p.productId === client?.productId)

    return {
      Email: email,
      'Date Time': ticket.dateTimeUtc,
      'Product Name': product?.name,
      'Product Title': product?.title,
      'Client Name': ticket.clientName,
      'Scenario Name': ticket.scenarioName,
      'UI Host': ticket.uiHost,
      Ring: ticket.ring,
      'OCV Link': ticket.oCVLink,
      'OCV Language': ticket.oCVLanguage,
      'System Tags': ticket.categories,
      'Ado Link': ticket.vsoLink,
      'Ado Description': ticket.vsoDescription,
      'Ado Account': ticket.vsoAccount,
    }
  }

  async function fetchFromV2(): Promise<object> {
    return getRawV2Ticket(context, ticketId).promise
  }
}
