import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { z } from 'zod'
import { TicketScreenTelemetryTab, TicketScreenTelemetryTabNames } from '../../../store/IState'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { TicketScreenOverflowTab } from '../../common/TicketScreenOverflowTab'
import { TicketScreenOverflowTabs } from '../../common/TicketScreenOverflowTabs'

export function TicketScreenTelemetryBar() {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()

  const supportedTabs = TicketScreenStore.use((state) => state.supportedTelemetryTabs)
  const selectedTab = TicketScreenStore.use((state) => state.selectedTelemetryTab)

  const tabs = useMemo(() => {
    return supportedTabs.map((tab): TicketScreenOverflowTab => {
      return {
        id: tab,
        name: TicketScreenTelemetryTabNames[tab].name,
      }
    })
  }, [supportedTabs])

  return (
    <TicketScreenOverflowTabs
      className={styles.root}
      tabs={tabs}
      selectedId={selectedTab}
      onSelect={(tabId) => {
        const tab = z.nativeEnum(TicketScreenTelemetryTab).safeParse(tabId).data
        if (tab) {
          actions.selectTelemetryTab(tab)
        }
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 4px',
  },
})
