import { TimeRange } from '@copilot-dash/core'
import {
  CopilotDashPath,
  DefaultProductChannelMapping,
  getProductIdByName,
  ProductIds,
  SearchFilterFieldNames,
  TeamViewSubMenuIds,
  teamViewSubMenuIdsSchema,
} from '@copilot-dash/domain'
import { isEqual, omitBy } from 'lodash'
import * as React from 'react'
import {
  ITicketFilterFormData,
  ITicketFilterFormRef,
} from '../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { getFilterItems } from '../../../components/TicketsFilterPanel/config/getFilterItems'
import { TicketsFilterPanel } from '../../../components/TicketsFilterPanel/TicketsFilterPanel'
import { TeamRoute } from '../../../router'
import { useTeamViewStore } from '../store'

export const FilterPanel = React.memo(() => {
  const currentArgs = useTeamViewStore((state) => state.route.args)
  const form = useTeamViewStore((state) => state.tickets.filterForm)
  const currentQuery = useTeamViewStore((state) => state.currentQuery)

  const defaultRange = React.useMemo((): TimeRange | undefined => {
    if (currentArgs.subMenu === TeamViewSubMenuIds.RootCauses && !currentArgs.issueId) return undefined
    return {
      type: 'relative',
      value: currentArgs.ticketStatus === 'UnRootCaused' ? 30 : 180,
      unit: 'days',
    }
  }, [currentArgs.ticketStatus, currentArgs.subMenu, currentArgs.issueId])

  const formRef = React.useRef<ITicketFilterFormRef | null>(null)

  const handleReset = React.useCallback(() => {
    useTeamViewStore.getState().tickets.resetFilterForm()
    const currentArgs = useTeamViewStore.getState().route.args
    const ticketsTabType = useTeamViewStore.getState().tickets.ticketsTabType
    // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
    // the url monitor is in the src/screens/team/TeamScreen.tsx
    if (ticketsTabType === 'category') {
      TeamRoute.navigator.navigate({
        product: currentArgs.product,
        subMenu: currentArgs.subMenu,
        teamId: currentArgs.teamId,
        teamName: currentArgs.teamName,
        ticketStatus: currentArgs.ticketStatus,
        channel:
          currentArgs.subMenu === TeamViewSubMenuIds.RootCauses
            ? undefined
            : DefaultProductChannelMapping[currentArgs.product],
        topIssueBatchId: currentArgs.topIssueBatchId,
      })
    } else if (ticketsTabType === 'rootCause') {
      TeamRoute.navigator.navigate({
        product: currentArgs.product,
        subMenu: currentArgs.subMenu,
        teamName: currentArgs.teamName,
        issueId: currentArgs.issueId,
        vsoAccount: currentArgs.vsoAccount,
        channel: undefined,
        topIssueBatchId: currentArgs.topIssueBatchId,
      })
    }
  }, [])

  const onSubmit = React.useCallback(
    (data?: ITicketFilterFormData) => {
      // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      const currentArgs = useTeamViewStore.getState().route.args
      const updateForm = {
        ...data,
        tenantIds: currentArgs.tenantIds,
        searchText: currentArgs.searchText,
        searchTextPrefix: currentArgs.searchTextPrefix,
        userId: currentArgs.userId,
        product: currentArgs.product,
        subMenu: currentArgs.subMenu,
        teamName: currentArgs.teamName,
        ticketStatus: currentArgs.ticketStatus,
        issueId: currentArgs.issueId,
        vsoAccount: currentArgs.vsoAccount,
        topIssueBatchId: currentArgs.topIssueBatchId,
      }

      Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FilterPanel', value: null })

      if (currentArgs.queryId && currentQuery) {
        const filterPayload = JSON.parse(currentQuery.filterPayload)

        const cleanedUpdateForm = omitBy(updateForm, (value, key) => isEqual(value, filterPayload[key]))
        if (cleanedUpdateForm.subMenu) {
          cleanedUpdateForm.subMenu = teamViewSubMenuIdsSchema.parse(filterPayload.subMenu)
        }

        TeamRoute.navigator.navigate({
          ...cleanedUpdateForm,
          product: currentArgs.product,
          //queryId: currentArgs.queryId,
        })
      } else {
        TeamRoute.navigator.navigate({
          ...updateForm,
        })
      }
    },
    [currentQuery],
  )

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? defaultRange,
      })
    }
  }, [defaultRange, form, currentArgs.subMenu])

  const isTicketsFilterPanelOpen = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const teamViewCustomizedFilters = app.settings.teamViewCustomizedFilterItems.use()
  const supportedTicketFilterItems: SearchFilterFieldNames[] = React.useMemo(() => {
    const filterItems = getFilterItems(
      getProductIdByName(currentArgs.product) ?? ProductIds.M365Chat,
      'team',
      currentArgs.teamName,
      currentArgs.subMenu,
    )
    if (currentArgs.subMenu === TeamViewSubMenuIds.RootCauses && !currentArgs.issueId) {
      return [...filterItems, 'range']
    }
    return filterItems
  }, [currentArgs.product, currentArgs.teamName, currentArgs.subMenu, currentArgs.issueId])

  return (
    <TicketsFilterPanel
      ref={formRef}
      isPanelOpen={isTicketsFilterPanelOpen}
      onDismiss={() => {
        useTeamViewStore.getState().toggleFilterPanel(false)
      }}
      onClickReset={handleReset}
      isAIF={false}
      selectedProductId={getProductIdByName(currentArgs.product)}
      ticketFilterForm={form}
      onSubmitFilterPanel={onSubmit}
      defaultRange={defaultRange}
      supportedTicketFilterItems={supportedTicketFilterItems}
      customizedFilters={teamViewCustomizedFilters}
      productName={currentArgs.product}
      copilotDashPath={CopilotDashPath.team}
      externalArgs={currentArgs}
      currentQuery={currentQuery}
    />
  )
})

FilterPanel.displayName = 'FilterPanel'
