import { getProductNameById } from '@copilot-dash/domain'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { TicketScreen } from '../../../screens/ticket/TicketScreen'
import { TicketRoute } from './TicketRoute'

export function TicketRoutePage() {
  const args = TicketRoute.navigator.useArgs()
  const title = `Ticket - ${args.id}`

  const productId = app.store.use.getTicketEnvironment(args.id)?.data?.productId
  const productName = getProductNameById(productId)

  return (
    <GlobalProductSelector
      value={productName}
      onChange={() => {}}
      isItemDisabled={(product) => product.productId !== productId}
    >
      <PageTitle title={title}>
        <TicketScreen ticketId={args.id} />
      </PageTitle>
    </GlobalProductSelector>
  )
}
