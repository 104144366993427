import { DefaultProductEndpoints, DefaultProducts } from '@copilot-dash/domain'

export function getWordTooltipForTicketClient(value: string): string | undefined {
  const endpoint = DefaultProductEndpoints.find((endpoint) => endpoint.name.toLowerCase() === value.toLowerCase())
  if (!endpoint) {
    return undefined
  }

  const product = DefaultProducts.find((product) => product.productId === endpoint.productId)
  if (!product) {
    return undefined
  }

  let content = ''
  content += `**${product.title}**\n`
  content += '```json\n' + JSON.stringify(endpoint, null, 2) + '\n```'
  return content
}
